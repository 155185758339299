import { SVGProps } from "react";

const ArrowTopRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.81087 11L9.23245 3.57842V9.16515H11.332V0H2.16689V2.09959H7.75361L0.332031 9.51203L1.81087 10.9909V11Z"
      fill={props.fill}
    />
  </svg>
);

export default ArrowTopRight;
