import { FC } from "react";
import { cn } from "utils/tailwind";
import styles from "./NavToggler.module.scss";

type TogglerProps = {
  id: string;
  opened: boolean;
  className?: string;
  onToggle: () => void;
};

export const NavToggler: FC<TogglerProps> = ({
  id,
  opened,
  className,
  onToggle,
}) => {
  const baseClasses = cn(
    `relative block size-[40px] cursor-pointer border-none bg-transparent p-[8px] lg:hidden`
  );

  return (
    <button
      id={id}
      className={cn(
        baseClasses,
        className,
        styles.toggler,
        opened ? styles.opened : ""
      )}
      onClick={onToggle}
      aria-label="Open Menu"
      aria-expanded={opened ? "true" : "false"}
      aria-haspopup="true"
      aria-controls="menu"
    >
      <div className="absolute left-1/2 top-1/2 block h-[15px] w-[25px] -translate-x-1/2 -translate-y-1/2 lg:hidden">
        <span />
        <span />
        <span />
      </div>
    </button>
  );
};
