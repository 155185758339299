import React, { SVGProps } from "react";

const VirtusLogoWithText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={97}
    height={38}
    viewBox="0 0 97 38"
    {...props}
  >
    <path d="M28.42 25.02h1.722l2.142 5.852 2.044-5.852h1.722L33.222 33h-1.876l-2.926-7.98ZM37.821 33v-7.98h1.624V33h-1.624Zm5.087-4.214h2.044c.434 0 .869-.182.869-1.274 0-.994-.658-1.064-.868-1.064h-2.045v2.338ZM41.285 33v-7.98h3.78c1.12 0 2.38.434 2.38 2.408 0 1.358-.379 2.31-1.303 2.492l1.68 3.08h-1.764l-1.483-2.926c-.267-.028-1.653-.154-1.667-.168V33h-1.623Zm7.782-6.454V25.02h6.44v1.526h-2.408V33h-1.624v-6.454h-2.408Zm8.197 2.814v-4.34h1.639v4.34c0 1.862.574 2.184 1.806 2.184 1.105 0 1.735-.336 1.735-2.184v-4.34h1.638v4.34c0 3.262-1.68 3.71-3.373 3.71-1.905 0-3.459-.364-3.445-3.71Zm8.617-1.54v-.728c0-1.176.476-2.114 2.072-2.114.728 0 2.828.112 3.878.308v1.19s-2.184-.084-3.626-.084c-.56 0-.7.35-.7.728v.588c0 .616.266.63.7.63h2.086c1.414 0 1.918.91 1.918 2.03v.784c0 1.61-.966 1.988-1.918 1.988-.672 0-3.57-.084-4.102-.322v-1.162s2.366.056 3.752.056c.49 0 .644-.588.644-.588v-.7c0-.406-.168-.602-.644-.602h-2.03c-1.47 0-2.03-.658-2.03-2.002Zm8.33 3.682V25.02h1.623v5.88c-.014.574.28.574.728.574h3.29V33h-4.144c-.868 0-1.498-.49-1.498-1.498Zm9.771-1.694H85.9l-.91-2.716-1.008 2.716ZM81.098 33l2.968-7.98h1.862L88.686 33h-1.722l-.658-1.806H83.52L82.82 33h-1.722Zm10.984-4.774H94.1c.7 0 .882-.266.882-.938 0-.728-.49-.854-.882-.854h-2.017v1.792Zm0 3.332H94.1c.405 0 .91-.098.91-.994 0-.63-.21-1.05-.91-1.05 0 0-1.695.014-2.017 0v2.044ZM90.46 33v-7.994h3.737c1.737 0 2.409.938 2.409 2.142 0 1.078-.42 1.582-1.023 1.778.533.154 1.065.714 1.065 1.806 0 1.442-.687 2.268-2.45 2.268h-3.739Z" />
    <g clipPath="url(#a)">
      <path d="M45.947 7.168a.673.673 0 0 0-.63-.43h-2.76a.708.708 0 0 0-.403.14L38 10.087l-.067-.139L33.04.512s-.014-.028-.027-.042V.442H33l-.04-.041-.08-.083c-.028-.028-.041-.042-.055-.042a.398.398 0 0 0-.107-.055c-.04-.014-.053-.028-.067-.028-.053-.013-.107-.027-.16-.027l-.04-.139v.139s-.121 0-.175.014h-.014L21.514 3.155a.724.724 0 0 0-.456.415L10.402 31.98l-3.418-8.649a.666.666 0 0 0-.536-.429l-5.71-.775H.644a.64.64 0 0 0-.563.318.686.686 0 0 0 0 .748l8.485 14.53.04.041.027.042s.026.028.026.041c.027.014.054.042.08.056l.054.041s.054.028.094.042l.054.028s.107.027.16.027h2.668c.241 0 .469-.138.576-.346l15.99-28.23 6.233 4.498s.053.027.094.041c.026 0 .053.014.093.028.04 0 .054.014.067.014l4.25.442c.2 0 .401-.055.549-.207L45.8 7.915a.724.724 0 0 0 .147-.76v.013ZM9.007 35.716 1.986 23.677l3.927.526.027.07 3.78 9.575-.698 1.868h-.013Zm2.4.886H10.12l.952-2.532L22.21 4.386h.067l8.766-2.435-.161.29-19.448 34.361h-.027ZM34.796 12.44l-5.764-4.179 3.391-5.992.108.222 4.369 8.455-2.024 1.563-.08-.055v-.014Zm4.101.637-2.211-.235 6.098-4.72h.912l-4.799 4.941v.014Z" />
    </g>
    <defs>
      <clipPath id="logo">
        <path d="M0 0h46v38H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default VirtusLogoWithText;
