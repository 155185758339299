import ArrowLeftShort from "@icons/arrow-left-short";
import ArrowRightShort from "@icons/arrow-right-short";
import ArrowIcon from "./arrow-icon";
import ArrowRight from "./arrow-right";
import ArrowRightThin from "./arrow-right-thin";
import ArrowSkewThin from "./arrow-skew-thin";
import ArrowTopRight from "./arrow-top-right";
import Box from "./box";
import BulbLightOn from "./bulb-light-on";
import Business2018 from "./business-2018";
import Business2020 from "./business-2020";
import Business2021 from "./business-2021";
import Chart from "./chart";
import ChartGrowth from "./chart-growth";
import Circle from "./circle";
import CircledPlus from "./circled-plus";
import Copy from "./copy";
import Crate from "./crate";
import DataAnalytics from "./data-analytics";
import Diamond from "./diamond";
import Digital from "./digital";
import Error from "./error-icon";
import facebook from "./facebook";
import FloatingArrowLeft from "./floating-arrow-left";
import Forbes2019 from "./forbes-2019";
import Forbes2022 from "./forbes-2022";
import FT2019 from "./ft-2019";
import FT2020 from "./ft-2020";
import FT2021 from "./ft-2021";
import github from "./github";
import Hexagon from "./hexagon";
import Info from "./info-icon";
import Infrastructure from "./infrastructure";
import IsoCertification from "./iso-certification";
import Legacy from "./legacy";
import linkedin from "./linkedIn";
import LinkedInGray from "./linkedIn-gray";
import Management from "./management";
import Maze from "./maze";
import Nord from "./nord";
import Optimisation from "./optimisation";
import PartnerNord from "./partner-nord";
import PlayArrow from "./play-arrow";
import Plus from "./plus";
import Quote from "./quote";
import RssIcon from "./rss";
import Scala from "./scala";
import SoftwaremillLogo from "./softwaremill-logo";
import Solutions from "./solutions";
import StarOutline from "./star-outline";
import Strategy from "./strategy";
import Success from "./success-icon";
import Thick from "./thick";
import TimeOutline from "./time-outline";
import TisaxCertification from "./tisax-certification";
import Transformation from "./transformation";
import muskx from "./twitter-new";
import VirtusLogo from "./virtus-logo";
import VirtusLogoWithText from "./virtus-logo-with-text";
import WarningIcon from "./warning-icon";

export const allIcons = {
  ArrowRightShort,
  ArrowIcon,
  ArrowRight,
  Box,
  Business2018,
  Business2020,
  Business2021,
  Crate,
  Diamond,
  Error,
  facebook,
  Forbes2019,
  Forbes2022,
  FT2019,
  FT2020,
  FT2021,
  FloatingArrowLeft,
  github,
  Info,
  IsoCertification,
  linkedin,
  LinkedInGray,
  Maze,
  Nord,
  PartnerNord,
  PlayArrow,
  Plus,
  Quote,
  RssIcon,
  SoftwaremillLogo,
  Success,
  Thick,
  TisaxCertification,
  muskx,
  VirtusLogo,
  VirtusLogoWithText,
  ArrowTopRight,
  Chart,
  ChartGrowth,
  BulbLightOn,
  Hexagon,
  Circle,
  CircledPlus,
  Copy,
  warning: WarningIcon,
  "Data-analytics": DataAnalytics,
  Digital,
  Infrastructure,
  Legacy,
  Management,
  Optimisation,
  Scala,
  Solutions,
  StarOutline,
  Strategy,
  Transformation,
  TimeOutline,
  ArrowLeftShort,
  ArrowRightThin,
  ArrowSkewThin,
} as const;

export type IconsNames = keyof typeof allIcons;
export const staticIconName = Object.keys(allIcons) as IconsNames[];
/* BEGIND storybook icons variables */
const excludeIcons = [
  "Business2018",
  "Business2020",
  "Business2021",
  "Forbes2019",
  "Forbes2022",
  "FT2019",
  "FT2020",
  "FT2021",
  "IsoCertification",
  "Nord",
  "PartnerNord",
  "SoftwaremillLogo",
  "TisaxCertification",
];
export const iconsArrayStorybook = Object.keys(allIcons)
  .filter((name) => !excludeIcons.includes(name))
  .sort((a, b) => a.localeCompare(b));
/* END storybook icons variables */

export const getStaticIcon = (icon: string | undefined) =>
  staticIconName.find((i) => i === icon) ?? "Box";

export default allIcons;
