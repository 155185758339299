import { SVGProps } from "react";

const Copy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M7 9.667C7 8.95967 7.28099 8.28131 7.78115 7.78115C8.28131 7.28099 8.95967 7 9.667 7H18.333C18.6832 7 19.03 7.06898 19.3536 7.20301C19.6772 7.33704 19.9712 7.53349 20.2189 7.78115C20.4665 8.0288 20.663 8.32281 20.797 8.64638C20.931 8.96996 21 9.31676 21 9.667V18.333C21 18.6832 20.931 19.03 20.797 19.3536C20.663 19.6772 20.4665 19.9712 20.2189 20.2189C19.9712 20.4665 19.6772 20.663 19.3536 20.797C19.03 20.931 18.6832 21 18.333 21H9.667C9.31676 21 8.96996 20.931 8.64638 20.797C8.32281 20.663 8.0288 20.4665 7.78115 20.2189C7.53349 19.9712 7.33704 19.6772 7.20301 19.3536C7.06898 19.03 7 18.6832 7 18.333V9.667Z"
      stroke="#D8DFE3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.012 14.737C1.705 14.5626 1.44965 14.31 1.2719 14.0049C1.09415 13.6998 1.00034 13.3531 1 13V3C1 1.9 1.9 1 3 1H13C13.75 1 14.158 1.385 14.5 2M11 14H14H17"
      stroke="#D8DFE3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 17L14 14L14 11"
      stroke="#D8DFE3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Copy;
