import { SVGProps } from "react";

const Chart = ({
  className,
  width,
  height,
  stroke,
  fill,
  color,
}: SVGProps<SVGSVGElement> & {
  stroke?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 35 34"
    fill="none"
    className={className}
    stroke={stroke || fill || color}
  >
    <path
      d="M22.8876 1H12.4455C8.67247 1 6.78597 1 5.34488 1.73427C4.07727 2.38016 3.04666 3.41076 2.40078 4.67838C1.6665 6.11947 1.6665 8.00596 1.6665 11.7789V22.2211C1.6665 25.994 1.6665 27.8805 2.40078 29.3216C3.04666 30.5892 4.07727 31.6198 5.34488 32.2657C6.78597 33 8.67247 33 12.4455 33H22.8876C26.6605 33 28.547 33 29.9881 32.2657C31.2557 31.6198 32.2863 30.5892 32.9322 29.3216C33.6665 27.8805 33.6665 25.994 33.6665 22.2211V11.7789C33.6665 8.00596 33.6665 6.11947 32.9322 4.67838C32.2863 3.41076 31.2557 2.38016 29.9881 1.73427C28.547 1 26.6605 1 22.8876 1Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7034 16.7313V25.1038M23.4015 11.7078V25.1038M10.0054 18.4058V25.1038"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Chart;
