export const changeColorsLogic = (
  initPos: boolean,
  lastOpen: boolean,
  darkMode: boolean
): boolean => initPos && !lastOpen && darkMode;

export const setScrollEnabled = (enable: boolean) => {
  const body = document.querySelector("body");
  if (body) body.classList.toggle("overflow-hidden", !enable);
  return undefined;
};

export const closeAllDropDowns = () => {
  const dropElements = document.querySelectorAll(
    '[data-nav-item="menu-dropdown"]'
  );
  const mainItems = document.querySelectorAll('[data-nav-item="main-item"]');

  Array.from(dropElements).map((item) => {
    item.classList.remove("block");
    item.classList.add("hidden");
  });

  Array.from(mainItems).map((item) => {
    item.setAttribute("aria-expanded", "false");
  });
};

export const closeAllOthersSubdropdowns = () => {
  const dropElements = document.querySelectorAll(
    '[data-nav-item="menu-subdropdown"]'
  );
  const labelItems = document.querySelectorAll('[data-nav-item="label-item"]');

  Array.from(dropElements).map((item) => {
    item.classList.remove("block");
    item.classList.add("hidden");
  });

  Array.from(labelItems).map((item) => {
    item.setAttribute("aria-expanded", "false");
  });
};
