import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";

type NavLogoProps = {
  className?: string;
  color?: "currentColor" | "white";
};

export const NavLogo: FC<NavLogoProps> = ({
  className,
  color = "currentColor",
}) => {
  const baseClasses = cn(
    `block w-[125px] pb-4 pt-3 focus:outline-disabled focus:text-green-saturated`
  );
  return (
    <Link
      href="/"
      className={cn(baseClasses, className)}
      title="VirtusLab Home"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="125"
        height="50"
        viewBox="0 0 125 50"
        fill="none"
        name="VirtusLab Logo"
      >
        <path
          id="V-ka"
          d="M65.377 8.96603C65.1867 8.71535 64.8766 8.57879 64.5564 8.57879H60.4805C60.267 8.57879 60.0596 8.64512 59.8884 8.76802L54.2064 12.8521L47.6474 0.774458C47.3222 0.176525 46.6133 -0.12488 45.9397 0.0487451L33.1127 3.35444C31.5359 3.76119 30.2541 4.87415 29.6661 6.34898L15.6993 41.3558L10.6436 30.4887C10.5017 30.1834 10.2056 29.9707 9.86127 29.9288L1.1183 28.8617C0.735677 28.8149 0.360101 28.9865 0.153685 29.3026C-0.052731 29.6186 -0.0507172 30.0214 0.156706 30.3355L12.5658 49.1075C12.9333 49.6635 13.5687 50 14.2504 50H17.4262C17.7786 50 18.1048 49.8186 18.283 49.524L40.6061 12.6521L48.2304 17.8882C48.7631 18.254 49.3884 18.4744 50.0398 18.5252L55.8185 18.9748C55.8447 18.9768 55.8718 18.9777 55.898 18.9777C56.1618 18.9777 56.4156 18.8763 56.6039 18.6929L65.2844 10.2194C65.6227 9.88878 65.6761 9.36108 65.377 8.96603ZM13.4791 46.9186L2.97504 31.0291L9.05977 31.7714L14.6924 43.8783L13.4791 46.9186ZM15.1495 48.0745L31.541 6.98983C31.8863 6.12269 32.6395 5.46915 33.5669 5.23018L44.5019 2.41219L17.0919 47.6873C16.9469 47.9272 16.681 48.0745 16.3941 48.0745H15.1495ZM41.6161 10.9852L46.5348 2.86088L52.5903 14.0138L49.3813 16.3207L41.6161 10.9861V10.9852ZM55.5184 17.0191L52.1131 16.7548L60.8087 10.5043H62.1932L55.5184 17.0191Z"
          fill={color}
        />
        <path
          d="M37.7304 29.961L40.6092 37.579L43.356 29.961H45.6699L41.8698 40.3492H39.3485L35.4165 29.961H37.7304Z"
          fill={color}
        />
        <path
          d="M47.5407 29.961H49.7227V40.3492H47.5407V29.961Z"
          fill={color}
        />
        <path
          d="M52.2933 29.961H57.3731C58.8785 29.961 60.5711 30.5257 60.5711 33.096C60.5711 34.8634 60.0626 36.1032 58.8211 36.3402L61.0786 40.3492H58.7083L56.7136 36.5402C56.3562 36.5041 54.4934 36.3392 54.4753 36.3217V40.3492H52.2933V29.961ZM57.2221 34.8634C57.8051 34.8634 58.3881 34.6264 58.3881 33.2052C58.3881 31.9108 57.504 31.8201 57.2221 31.8201H54.4753V34.8634H57.2221Z"
          fill={color}
        />
        <path
          d="M62.4036 29.961H71.058V31.9479H67.8218V40.3492H65.6398V31.9479H62.4036V29.961Z"
          fill={color}
        />
        <path
          d="M73.0174 29.961H75.2186V35.6106C75.2186 38.0345 75.9898 38.454 77.6452 38.454C79.1314 38.454 79.9782 38.017 79.9782 35.6106V29.961H82.1793V35.6106C82.1793 39.8566 79.9218 40.4399 77.6452 40.4399C75.0866 40.4399 72.9983 39.9659 73.0174 35.6106V29.961Z"
          fill={color}
        />
        <path
          d="M84.3522 32.658C84.3522 31.1276 84.9916 29.9063 87.1363 29.9063C88.115 29.9063 90.9363 30.0517 92.347 30.3072V31.8562C92.347 31.8562 89.4119 31.747 87.4746 31.747C86.7225 31.747 86.5341 32.2025 86.5341 32.6951V33.4608C86.5341 34.2626 86.8916 34.2811 87.4746 34.2811H90.2778C92.1779 34.2811 92.8555 35.4653 92.8555 36.9235V37.9438C92.8555 40.04 91.5576 40.5316 90.2778 40.5316C89.3746 40.5316 85.4809 40.4224 84.766 40.1122V38.5993C84.766 38.5993 87.9458 38.6724 89.8076 38.6724C90.4661 38.6724 90.6725 37.9067 90.6725 37.9067V36.9957C90.6725 36.467 90.447 36.2124 89.8076 36.2124H87.0799C85.1043 36.2124 84.3522 35.356 84.3522 33.6061V32.658Z"
          fill={color}
        />
        <path
          d="M95.2902 29.961H97.4722V37.6151C97.453 38.3623 97.8488 38.3623 98.4509 38.3623H102.872V40.3492H97.304C96.138 40.3492 95.2912 39.7113 95.2912 38.3993V29.961H95.2902Z"
          fill={color}
        />
        <path
          d="M108.602 29.961H111.104L114.81 40.3492H112.496L111.612 37.9984H107.868L106.928 40.3492H104.614L108.603 29.961H108.602ZM111.066 36.1939L109.844 32.658L108.49 36.1939H111.067H111.066Z"
          fill={color}
        />
        <path
          d="M116.684 29.9424H121.707C124.04 29.9424 124.944 31.1637 124.944 32.7312C124.944 34.1348 124.379 34.7903 123.57 35.0458C124.285 35.2468 125 35.9754 125 37.3966C125 39.2733 124.078 40.3492 121.707 40.3492H116.684V29.9424ZM121.576 34.1338C122.516 34.1338 122.761 33.7875 122.761 32.9126C122.761 31.9645 122.102 31.8006 121.576 31.8006H118.867V34.1338H121.576ZM121.576 38.4715C122.121 38.4715 122.798 38.3437 122.798 37.1771C122.798 36.3568 122.516 35.8106 121.576 35.8106C121.576 35.8106 119.299 35.8291 118.867 35.8106V38.4715H121.576Z"
          fill={color}
        />
      </svg>
    </Link>
  );
};
