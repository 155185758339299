import { Button } from "@atoms/button/Button";
import { FC } from "react";

export const DesktopSubmitButton: FC<{
  loading: boolean;
  label?: string;
  show?: boolean;
}> = ({ loading, label, show = true }) => (
  <Button
    type="submit"
    intent="tertiaryV2"
    background="dark"
    icon="ArrowRight"
    loading={loading}
    loaderSize="lg"
    className={show ? "hidden border-none sm:block" : "hidden"}
  >
    {loading ? "" : label || "Submit"}
  </Button>
);
