"use client";

import { Navigation } from "@layout/navigation/Nav.types";
import { FC, MouseEventHandler, useEffect, useState } from "react";
import {
  changeColorsLogic,
  closeAllDropDowns,
  closeAllOthersSubdropdowns,
  setScrollEnabled,
} from "./Nav.helpers";
import { Backdrop } from "./components/backdrop/Backdrop";
import { ContactButton } from "./components/contact-button/ContactButton";
import { DropdownColumns } from "./components/dropdown-columns/DropdownColumns";
import { DropdownColumnsAndRelated } from "./components/dropdown-columns-and-related/DropdownColumnsAndRelated";
import { DropdownColumnsDescriptive } from "./components/dropdown-columns-descriptive/DropdownColumnsDescriptive";
import { DropdownContainer } from "./components/dropdown-container/DropdownContainer";
import { NavLogo } from "./components/logo/NavLogo";
import { MainLink } from "./components/main-link/MainLink";
import { NavigationContainer } from "./components/navigation-container/NavigationContainer";
import { Skipper } from "./components/skipper/Skipper";
import { NavToggler } from "./components/toggler/NavToggler";

export type NavProps = {
  navigation: Navigation;
  darkMode: boolean;
  greenContactBtn?: boolean;
};

export const Nav: FC<NavProps> = ({
  navigation,
  greenContactBtn,
  darkMode = false,
}) => {
  //utils
  const [isMobile, setIsMobile] = useState<boolean>(false);

  //desktop
  const [initialPosition, setInitialPosition] = useState<boolean>(true);
  const [lastOpenedMain, setLastOpenedMain] = useState<Element | null>(null);

  //mobile
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [lastOpenedSubdrop, setLastOpenedSubdrop] = useState<Element | null>(
    null
  );

  const onClickMainItem: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();

    const mainElement = event.target as HTMLElement;
    const dropdownId = mainElement.getAttribute("data-dropdown");
    const dropElement = dropdownId && document.querySelector(`#${dropdownId}`);

    if (lastOpenedMain !== dropElement) {
      closeAllDropDowns();
      closeAllOthersSubdropdowns();
      setLastOpenedMain(null);
    }

    if (dropElement) {
      dropElement.classList.toggle("hidden");
      dropElement.classList.toggle("block");

      if (lastOpenedMain !== dropElement) {
        setLastOpenedMain(dropElement);
        mainElement.setAttribute("aria-expanded", "true");
      } else {
        setLastOpenedMain(null);
        mainElement.setAttribute("aria-expanded", "false");
      }
    }
  };

  const onClickSubdropdown: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();

    if (isMobile) {
      const subDropdown = event.target as HTMLAnchorElement;
      const subDropdownId = subDropdown.getAttribute("data-subdropdown");
      const subdropElement =
        subDropdownId && document.querySelector(`#${subDropdownId}`);

      if (lastOpenedSubdrop !== subdropElement) {
        closeAllOthersSubdropdowns();
        setLastOpenedSubdrop(null);
      }

      if (subdropElement) {
        subdropElement.classList.toggle("hidden");
        subdropElement.classList.toggle("block");
        subDropdown.setAttribute("aria-expanded", "true");

        if (lastOpenedMain !== subdropElement) {
          setLastOpenedSubdrop(subdropElement);
          subDropdown.setAttribute("aria-expanded", "true");
        } else {
          setLastOpenedSubdrop(null);
          subDropdown.setAttribute("aria-expanded", "true");
        }
      }
    }
  };

  const closeDesktopDropdown = () => {
    closeAllDropDowns();
    closeAllOthersSubdropdowns();
    setLastOpenedMain(null);
  };

  const menuToggle = () => {
    closeAllDropDowns();
    closeAllOthersSubdropdowns();
    setMobileOpen(!mobileOpen);
  };

  const menuCloseToggle: MouseEventHandler<HTMLDivElement> = (event) => {
    if ((event.target as HTMLDivElement).id === "closeToggle" && isMobile)
      menuToggle();
  };

  useEffect(() => {
    const handleResize = () => {
      const mobileView = window.innerWidth < 1024;
      setIsMobile(mobileView);

      if (mobileView) {
        closeAllDropDowns();
        closeAllOthersSubdropdowns();
        setLastOpenedMain(null);
      } else {
        setMobileOpen(false);
      }
    };

    const handleInitialPosition = () => {
      const initPosBool = window.scrollY > 5;
      if (initPosBool)
        document.documentElement.style.setProperty("--nav-height", "78px");
      else document.documentElement.style.setProperty("--nav-height", "100px");
      setInitialPosition(!initPosBool);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleInitialPosition);

    handleResize();
    handleInitialPosition();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleInitialPosition);
    };
  }, []);

  useEffect(() => {
    setScrollEnabled(!mobileOpen);

    return () => setScrollEnabled(true);
  }, [mobileOpen]);

  const changeMenuColor = changeColorsLogic(
    initialPosition,
    Boolean(lastOpenedMain),
    darkMode
  );

  return (
    <Skipper>
      <NavigationContainer
        initialPosition={initialPosition}
        lastOpenedMain={Boolean(lastOpenedMain)}
        mobileOpen={mobileOpen}
        darkMode={darkMode}
      >
        {/* Logo/Toggle Wrapper */}
        <div className="flex w-full justify-between px-8 lg:h-auto lg:w-[calc(125px+64px)] lg:pl-0 lg:pr-16 [@media(max-width:1023px)]:bg-white">
          <NavLogo color={!isMobile && changeMenuColor ? "white" : undefined} />
          <div className="pt-[26px]">
            <NavToggler
              opened={mobileOpen}
              id="toggleButton"
              onToggle={menuToggle}
            />
          </div>
        </div>
        {/* END Logo/Toggle Wrapper */}

        <Backdrop state={mobileOpen} onClick={menuCloseToggle} id="closeToggle">
          <ul
            id="menu"
            role="menu"
            className="block px-7 py-2 md:px-14 md:py-7 lg:flex lg:p-0 lg:pt-[18px] [@media(max-width:1023px)]:bg-white"
          >
            {navigation.nodes.map((node, idx) => (
              <MainLink
                key={idx}
                node={node}
                darkMode={darkMode}
                dropdownId={`dropdown-${idx}`}
                initialPosition={initialPosition}
                changeMenuColor={changeMenuColor}
                lastOpenedMain={Boolean(lastOpenedMain)}
                onClickMainItem={onClickMainItem}
              >
                <DropdownContainer
                  id={`dropdown-${idx}`}
                  node={node}
                  closeDesktopDropdown={closeDesktopDropdown}
                >
                  {node.layout === "columns" && (
                    <DropdownColumns
                      node={node}
                      isMobile={isMobile}
                      onClickSubdropdown={onClickSubdropdown}
                    />
                  )}

                  {node.layout === "two-sided" && (
                    <DropdownColumnsAndRelated node={node} />
                  )}

                  {node.layout === "horizontal" && (
                    <DropdownColumnsDescriptive node={node} />
                  )}
                </DropdownContainer>
              </MainLink>
            ))}
          </ul>
          <ContactButton
            darkTheme={!isMobile && changeMenuColor}
            buttonText={navigation.contactButtonLabel || "Let's connect"}
            forceGreenColor={!isMobile && (greenContactBtn || !initialPosition)}
          />
        </Backdrop>
      </NavigationContainer>
    </Skipper>
  );
};
