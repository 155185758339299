import React, { SVGProps } from "react";

const Facebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={20}
    viewBox="0 0 13 20"
    fill="#E3F2FA"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="m4.012 20-.03-8.75H.125V7.5h3.857V5c0-3.374 2.15-5 5.246-5 1.482 0 2.757.107 3.128.156V3.68l-2.146.003c-1.685 0-2.01.777-2.01 1.919V7.5h4.782l-1.285 3.75H8.2V20H4.012Z"
      clipRule="evenodd"
    />
  </svg>
);

export default Facebook;
