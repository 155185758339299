import { SVGProps } from "react";

const ChartGrowth = ({
  stroke,
  ...props
}: SVGProps<SVGSVGElement> & {
  stroke?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    stroke={props.fill || props.color}
    {...props}
  >
    <path
      d="M5.552 19.4004L26.352 2.40039M26.352 2.40039L20.0135 2.40039M26.352 2.40039L26.352 8.61705M26.352 14.3004V29.6004M19.4187 17.7004V29.6004M12.4853 22.8004V29.6004M5.552 26.667V29.6004"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={stroke}
    />
  </svg>
);

export default ChartGrowth;
