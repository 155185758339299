import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import { ArticleLink } from "@layout/navigation/Nav.types";
import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";
import { twMerge } from "tailwind-merge";
import styles from "./FeaturedLink.module.scss";

interface FeaturedLinkProps {
  className?: string;
  item: ArticleLink;
}

export const FeaturedLink: FC<FeaturedLinkProps> = ({
  className,
  item,
  ...props
}) => (
  <li
    className={twMerge(
      "mb-5 rounded-lg border border-[#8C9DA140] bg-[#FFFFFF66] text-sm last:mb-0 hover:bg-white",
      className
    )}
    {...props}
  >
    <Link
      href={item.href}
      className={cn("flex p-4 hover:text-green-accent-two", styles.feturedLink)}
    >
      {item.image && (
        <div className="h-[85px] w-[165px] overflow-hidden rounded-lg">
          <Image
            imageData={item.image}
            width={165}
            maxKnownWidth={175}
            className="size-full object-cover"
          />
        </div>
      )}
      <div className="w-[calc(100%-180px)] font-termina text-xs font-medium text-content-primary-on-light lg:pl-7">
        <span>{item.name}</span>
      </div>
      <div className="flex w-[15px] items-end">
        <Icon name="ArrowRight" width={15} color="currentColor" />
      </div>
    </Link>
  </li>
);
