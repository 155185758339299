import { SVGProps } from "react";

const ArrowSkewThin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="10"
    viewBox="0 0 9 10"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.30962 2.92707L0.941585 9.2951L0.129482 8.483L6.49751 2.11497L1.62439 1.87762L1.57105 0.78247L8.31371 1.11088L8.64211 7.85354L7.54696 7.8002L7.30962 2.92707Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowSkewThin;
