import React, { SVGProps } from "react";

const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M0 40C0 62.0914 17.9086 80 40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40ZM72.7273 40C72.7273 58.0748 58.0748 72.7273 40 72.7273C21.9253 72.7273 7.27275 58.0748 7.27275 40C7.27275 21.9253 21.9253 7.27275 40 7.27275C58.0748 7.27275 72.7273 21.9253 72.7273 40ZM40.0012 61.8119C42.0102 61.8119 43.6388 60.1838 43.6388 58.1755C43.6388 56.1672 42.0102 54.5392 40.0012 54.5392C37.9923 54.5392 36.3637 56.1672 36.3637 58.1755C36.3637 60.1838 37.9923 61.8119 40.0012 61.8119ZM43.6494 18.1757H36.3743V47.2666H43.6494V18.1757Z"
      clipRule="evenodd"
    />
  </svg>
);
export default WarningIcon;
