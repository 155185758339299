import React, { SVGProps } from "react";

const Github = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="#E3F2FA"
    {...props}
  >
    <path d="M10.491 0c-5.523 0-10 4.59-10 10.254 0 4.53 2.865 8.373 6.84 9.728.5.094.681-.222.681-.494 0-.243-.008-.889-.013-1.744-2.782.62-3.369-1.374-3.369-1.374-.454-1.186-1.11-1.5-1.11-1.5-.908-.635.07-.623.07-.623 1.002.071 1.53 1.056 1.53 1.056.892 1.566 2.341 1.114 2.91.852.092-.663.35-1.114.636-1.37-2.22-.259-4.555-1.14-4.555-5.068 0-1.119.39-2.035 1.03-2.751-.104-.26-.447-1.302.097-2.713 0 0 .84-.276 2.75 1.05a9.357 9.357 0 0 1 2.503-.345 9.383 9.383 0 0 1 2.504.345c1.91-1.326 2.747-1.05 2.747-1.05.546 1.41.202 2.454.1 2.713.64.716 1.028 1.632 1.028 2.751 0 3.94-2.339 4.806-4.566 5.06.36.316.678.942.678 1.899 0 1.37-.012 2.476-.012 2.812 0 .274.18.593.688.493a10.04 10.04 0 0 0 4.949-3.736 10.422 10.422 0 0 0 1.884-5.991C20.491 4.59 16.013 0 10.491 0Z" />
  </svg>
);

export default Github;
