import { postData } from "@utils/fetch/calls";

export type SubscribeBody = { email: string };

export const subscribeNewsletter = async (body: SubscribeBody) => {
  try {
    return await postData("/api/newsletter/", {
      body,
      signal: AbortSignal.timeout(7000),
    });
  } catch (error) {
    let errorMessage = "unknown error";
    if (error instanceof Error) {
      errorMessage =
        error.name === "TimeoutError"
          ? "request takes too long, try again"
          : error.message;
    }
    return { success: false, errorMessage };
  }
};
