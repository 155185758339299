import React, { SVGProps } from "react";

const Management = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 63.46 63.46"
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        x1={9529.71}
        x2={9519.77}
        y1={9522.93}
        y2={9535.64}
        gradientTransform="scale(1 -1) rotate(90 9561.78 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#26a4e1" stopOpacity={0} />
        <stop offset={1} stopColor="#26a4e1" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={22.58}
        x2={14.08}
        y1={47.91}
        y2={35.77}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.17} stopColor="#fe9c5a" />
        <stop offset={0.42} stopColor="#fead6d" />
        <stop offset={0.71} stopColor="#fdc88c" />
        <stop offset={1} stopColor="#fdeab3" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={5608.29}
        x2={5596.15}
        y1={16887.84}
        y2={16879.02}
        gradientTransform="rotate(138.79 5976.956 7407.295)"
      />
      <linearGradient
        id="d"
        x1={5601.33}
        x2={5597.52}
        y1={16883.22}
        y2={16889.63}
        gradientTransform="rotate(138.79 5976.956 7407.295)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.75} stopColor="#ff9754" stopOpacity={0} />
      </linearGradient>
    </defs>
    <path
      d="M24.91 24.54v15.1c0 .89.48 1.72 1.25 2.17l13.07 7.55c.77.45 1.73.45 2.5 0l13.07-7.55c.77-.45 1.25-1.27 1.25-2.17v-15.1c0-.89-.48-1.72-1.25-2.17l-13.07-7.55c-.77-.45-1.73-.45-2.5 0l-13.07 7.55c-.77.45-1.25 1.27-1.25 2.17Z"
      style={{
        fill: "#6cd7f3",
      }}
    />
    <path
      d="M40.48 35.18v13.83c0 .47-.51.76-.91.53l-13.3-7.68a2.725 2.725 0 0 1-1.36-2.36V24.44l14.1 8.17c.91.53 1.48 1.51 1.48 2.56Z"
      style={{
        fill: "url(#b)",
      }}
    />
    <path
      d="M15.33 29.36v-8.23c0-1.12.82-2.03 1.84-2.03h7.6"
      style={{
        fill: "none",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        stroke: "#6cd7f3",
        strokeWidth: "2.3px",
      }}
    />
    <path
      d="M15.33 36.11v6.92c0 1.12.82 2.03 1.84 2.03h7.74"
      style={{
        stroke: "url(#a)",
        strokeWidth: 2,
        fill: "none",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m16.5 40.81 5.38-3.11c.77-.45 1.25-1.27 1.25-2.17v-6.21c0-.89-.48-1.72-1.25-2.17l-5.38-3.11c-.77-.45-1.73-.45-2.5 0l-5.38 3.11c-.77.45-1.25 1.27-1.25 2.17v6.21c0 .89.48 1.72 1.25 2.17L14 40.81c.77.45 1.73.45 2.5 0Z"
      style={{
        fill: "url(#c)",
      }}
    />
    <path
      d="M14.96 32.3c.54.28 1.19.26 1.71-.06l5.59-3.46c.62-.55.46-1.13-.19-1.5l-5.96-3.44c-.54-.31-1.2-.31-1.73 0l-5.96 3.44c-.65.38-.54 1.43.31 1.78l6.22 3.24Z"
      style={{
        fill: "url(#d)",
      }}
    />
  </svg>
);

export default Management;
