import { cn } from "@utils/tailwind";
import { createElement, forwardRef } from "react";
import { fontVariantMap, fontWeightMap } from "./Typography.helpers";
import styles from "./Typography.module.css";
import { TypographyProps } from "./Typography.types";

const Typography = forwardRef<HTMLElement, TypographyProps>(
  (
    {
      tag,
      children,
      className,
      variant,
      weight,
      wysiwygStyling = false,
      ...rest
    },
    ref
  ) =>
    createElement(
      tag,
      {
        className: cn(
          variant && fontVariantMap[variant],
          wysiwygStyling && styles.wysiwygStyling,
          weight && fontWeightMap[weight],
          className
        ),
        ref,
        ...rest,
      },
      children
    )
);
Typography.displayName = "Typography";

export default Typography;
