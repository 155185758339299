import React, { SVGProps } from "react";

const Info = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={11}
    viewBox="0 0 11 11"
    fill="#E3F2FA"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M0 5.5a5.5 5.5 0 1 0 11 0 5.5 5.5 0 0 0-11 0Zm10 0a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-3.998.999h.5v1h-2v-1h.5v-1h-.5v-1h1.5v2Zm-.002-3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Info;
