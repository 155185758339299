import { SVGProps } from "react";

const PlayArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 12"
    fill="#012116"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.656244V11.3437C0 11.8135 0.519677 12.0972 0.914824 11.8432L9.22732 6.49944C9.59088 6.26572 9.59088 5.73426 9.22732 5.50054L0.914824 0.156795C0.519677 -0.0972285 0 0.18649 0 0.656244ZM7.80825 5.99936L1.1875 10.2556V1.74316L7.80825 5.99936Z"
    />
  </svg>
);

export default PlayArrow;
