import React, { SVGProps } from "react";

const Crate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="#1F2621"
    {...props}
  >
    <g clipPath="url(#clip0_4698_42253)">
      <path d="M18.4297 18.8933L19.9998 17.9765V16.1587L18.4297 17.0724V18.8933Z" />
      <path d="M18.4297 15.8423L19.9998 14.9271V13.0822L18.4297 13.999V15.8423Z" />
      <path d="M18.4297 12.7691L19.9998 11.8538V10.0089L18.4297 10.9226V12.7691Z" />
      <path d="M18.4297 9.6943L19.9998 8.78063V6.94849L18.4297 7.86216V9.6943Z" />
      <path d="M17.903 5.10193L16.3281 6.0204L17.8935 6.93247L19.4684 6.0156L17.903 5.10193Z" />
      <path d="M15.7959 20.4267L17.3739 19.5082V17.6873L15.7959 18.6073V20.4267Z" />
      <path d="M15.7959 17.3758L17.3739 16.4589V14.614L15.7959 15.5325V17.3758Z" />
      <path d="M15.7959 14.3026L17.3739 13.3825V11.5392L15.7959 12.4592V14.3026Z" />
      <path d="M15.7959 11.2276L17.3739 10.3092V8.47864L15.7959 9.3987V11.2276Z" />
      <path d="M15.2718 5.40541L16.8466 4.48855L15.2686 3.56848L13.6953 4.48535L15.2718 5.40541Z" />
      <path d="M15.2718 6.63379L13.6953 7.55385L15.2623 8.46593L16.8387 7.54746L15.2718 6.63379Z" />
      <path d="M13.1641 21.9617L14.7405 21.0417V19.2207L13.1641 20.1408V21.9617Z" />
      <path d="M13.1641 18.9093L14.7405 17.9924V16.1475L13.1641 17.0659V18.9093Z" />
      <path d="M13.1641 15.836L14.7405 14.916V13.0726L13.1641 13.9927V15.836Z" />
      <path d="M13.1641 12.7627L14.7405 11.8426V10.0121L13.1641 10.9321V12.7627Z" />
      <path d="M14.2186 6.02041L12.6421 5.10034L11.0625 6.02041L12.6421 6.93887L14.2186 6.02041Z" />
      <path d="M12.6421 3.87197L14.2138 2.9535L12.6374 2.03503L11.0625 2.9519L12.6421 3.87197Z" />
      <path d="M12.6421 8.16882L11.0625 9.08729L12.6295 10.001L14.2075 9.0809L12.6421 8.16882Z" />
      <path d="M10.5303 23.4952L12.1099 22.5751V20.7542L10.5303 21.6742V23.4952Z" />
      <path d="M10.5303 20.4459L12.1099 19.5258V17.6809L10.5303 18.601V20.4459Z" />
      <path d="M10.5303 17.3695L12.1099 16.4526V14.6077L10.5303 15.5261V17.3695Z" />
      <path d="M10.5303 14.2961L12.1099 13.3761V11.5471L10.5303 12.4656V14.2961Z" />
      <path d="M11.5861 4.48536L10.0081 3.56689L8.43164 4.48536L10.0081 5.40542L11.5861 4.48536Z" />
      <path d="M11.5861 7.55385L10.0081 6.63379L8.43164 7.55385L10.0081 8.47392L11.5861 7.55385Z" />
      <path d="M11.5814 1.42006L10.0033 0.5L8.43164 1.41847L10.0081 2.33853L11.5814 1.42006Z" />
      <path d="M10.0081 9.70227L8.43164 10.6223L9.99701 11.5344L11.5735 10.6143L10.0081 9.70227Z" />
      <path d="M7.88965 22.58L9.46926 23.5V21.6822L7.88965 20.7654V22.58Z" />
      <path d="M7.88965 19.5338L9.46926 20.4538V18.6089L7.88965 17.6888V19.5338Z" />
      <path d="M7.88965 16.4606L9.46926 17.3807V15.5358L7.88965 14.6157V16.4606Z" />
      <path d="M7.88965 13.3873L9.46926 14.3057V12.4608L7.88965 11.5424V13.3873Z" />
      <path d="M8.95233 2.95191L7.37588 2.03345L5.79785 2.95191L7.37588 3.87198L8.95233 2.95191Z" />
      <path d="M5.79785 6.02041L7.37588 6.93887L8.95233 6.02041L7.37588 5.10034L5.79785 6.02041Z" />
      <path d="M7.37588 8.16882L5.79785 9.08729L7.37588 10.0074L8.95233 9.08729L7.37588 8.16882Z" />
      <path d="M5.25586 21.0465L6.83547 21.9666V20.1488L5.25586 19.2288V21.0465Z" />
      <path d="M5.25586 18.0004L6.83547 18.9205V17.0756L5.25586 16.1555V18.0004Z" />
      <path d="M5.25586 14.9271L6.83547 15.8455V14.0006L5.25586 13.0822V14.9271Z" />
      <path d="M5.25586 11.8522L6.83547 12.7723V10.9274L5.25586 10.0073V11.8522Z" />
      <path d="M4.74209 6.63379L3.16406 7.55385L4.74209 8.47392L6.32012 7.55385L4.74209 6.63379Z" />
      <path d="M2.62598 19.5131L4.20242 20.4299V18.6154L2.62598 17.6953V19.5131Z" />
      <path d="M2.62598 16.467L4.20242 17.3855V15.5405L2.62598 14.6221V16.467Z" />
      <path d="M2.62598 13.392L4.20242 14.3121V12.4672L2.62598 11.5471V13.392Z" />
      <path d="M2.62598 10.3188L4.20242 11.2389V9.39394L2.62598 8.47388V10.3188Z" />
      <path d="M2.10867 5.10034L0.532227 6.02041L2.10867 6.93887L3.68671 6.02041L2.10867 5.10034Z" />
      <path d="M0 17.9828L1.57012 18.8965V17.0803L0 16.1666V17.9828Z" />
      <path d="M0 14.9383L1.57012 15.852V14.0071L0 13.0934V14.9383Z" />
      <path d="M0 11.8619L1.57012 12.7787V10.9338L0 10.0186V11.8619Z" />
      <path d="M0 8.78851L1.57012 9.70219V7.85887L0 6.94519V8.78851Z" />
      <path d="M6.32012 4.48536L4.74209 3.56689L3.16406 4.48536L4.74209 5.40542L6.32012 4.48536Z" />
    </g>
    <defs>
      <clipPath id="clip0_4698_42253">
        <rect
          width="20"
          height="23"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Crate;
