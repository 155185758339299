import { BorderWidth, Color, LoaderSize } from "./Loader.types";

export const sizeClasses: Record<LoaderSize, string> = {
  "2": "size-2",
  "4": "size-4",
};

export const colorClasses: Record<Color, string> = {
  black: "border-black border-b-transparent",
  white: "border-white border-b-transparent",
  transparent: "border-transparent border-b-transparent",
};

export const widthClasses: Record<BorderWidth, string> = {
  "1": "border-1",
  "2": "border-2",
  "4": "border-4",
  "8": "border-8",
};
