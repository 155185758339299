import { SVGProps } from "react";

const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="10"
    width="10"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.29937 8.5L6.16242 3.63693V6.93153H8V0.5H1.56847V2.33758H4.86307L0 7.20065L1.29937 8.5Z"
    />
  </svg>
);

export default ArrowIcon;
