import { RootNode } from "@layout/navigation/Nav.types";
import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC, HTMLAttributes, MouseEventHandler, ReactNode } from "react";
import styles from "./MainLink.module.scss";

interface MainLinkProps extends HTMLAttributes<HTMLDivElement> {
  node: RootNode;
  dropdownId: string;
  changeMenuColor: boolean;
  initialPosition: boolean;
  darkMode: boolean;
  lastOpenedMain: boolean;
  children: ReactNode | undefined;
  onClickMainItem: MouseEventHandler<HTMLAnchorElement>;
}

export const MainLink: FC<MainLinkProps> = ({
  node,
  dropdownId,
  changeMenuColor,
  initialPosition,
  darkMode,
  lastOpenedMain,
  children,
  onClickMainItem,
}) => (
  <li role="presentation" className="group/mainlink">
    <Link
      data-color={`${changeMenuColor ? "white" : "black"}`}
      data-dropdown={dropdownId}
      data-nav-item="main-item"
      href={node.link.href}
      role="menuitem"
      aria-expanded="false"
      aria-haspopup="true"
      className={cn(
        "focus:outline-disabled",
        "block border-b border-b-grey-scale-light-gray py-6 text-lg font-medium uppercase group-last/mainlink:border-none md:py-7 lg:border-none lg:px-4 lg:py-5 lg:text-sm",
        styles.mainItem,
        "aria-expanded:border-none [@media(max-width:1023px)]:aria-expanded:pb-2",
        initialPosition && darkMode ? "lg:text-white" : "",
        lastOpenedMain ? "lg:text-black" : ""
      )}
      onClick={onClickMainItem}
    >
      <span>
        {node.link.name}
        <span />
      </span>
    </Link>
    {children}
  </li>
);
