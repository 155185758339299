import React, { SVGProps } from "react";

const DataAnalytics = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.46 63.46" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={53.84}
        x2={10.02}
        y1={43.88}
        y2={43.88}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#009c3f" />
        <stop offset={0.42} stopColor="#08bb59" />
        <stop offset={1} stopColor="#14e37c" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={51.13}
        x2={1.35}
        y1={36.03}
        y2={36.03}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopOpacity={0.2} />
        <stop offset={0.12} stopOpacity={0.44} />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={49.45}
        x2={21.75}
        y1={16.49}
        y2={36.69}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.17} stopColor="#fe9c5a" />
        <stop offset={0.42} stopColor="#fead6d" />
        <stop offset={0.71} stopColor="#fdc88c" />
        <stop offset={1} stopColor="#fdeab3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={33.83}
        x2={33.83}
        y1={22.53}
        y2={40.62}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.75} stopColor="#ff9754" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="e"
        x1={10.02}
        x2={53.84}
        y1={21.44}
        y2={21.44}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#272723" stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
    </defs>
    <path
      d="M53.84 43.5c0 4.26-9.81 7.72-21.91 7.72s-21.91-3.46-21.91-7.72v-6.96h43.82v6.96Z"
      style={{
        fill: "url(#a)",
      }}
    />
    <ellipse
      cx={31.93}
      cy={36.54}
      rx={21.91}
      ry={7.72}
      style={{
        fill: "#14e37c",
      }}
    />
    <path
      d="M31.11 28.83h-.02L13.82 32.2s6.32 9.35 23.37 10.93c3.77.35 7.59-.07 11.11-1.46s5.55-3.16 5.55-5.13c0-4.36-10.26-7.87-22.73-7.71Z"
      style={{
        opacity: 0.3,
        fill: "url(#b)",
      }}
    />
    <ellipse
      cx={31.93}
      cy={29.27}
      rx={21.91}
      ry={7.72}
      style={{
        fill: "url(#c)",
      }}
    />
    <path
      d="M31.11 21.56h-.02l-17.27 3.37s7.54 11.16 28.38 11.16c6.93-1.3 11.65-3.86 11.65-6.82 0-4.36-10.26-7.87-22.73-7.71Z"
      style={{
        fill: "url(#d)",
      }}
    />
    <ellipse
      cx={31.93}
      cy={20.07}
      rx={21.91}
      ry={7.72}
      style={{
        fill: "#d4e6e8",
      }}
    />
    <path
      d="M15.18 15.1c-3.22 1.34-5.16 3.08-5.16 4.97 0 4.26 9.81 7.72 21.91 7.72 5.24 0 10.05-.65 13.82-1.73-10.74-.6-22.01-4.62-30.57-10.96Z"
      style={{
        fill: "url(#e)",
        opacity: 0.3,
      }}
    />
  </svg>
);

export default DataAnalytics;
