import { cva } from "class-variance-authority";
import {
  primaryButtonVariants,
  primaryCompoundVariants,
  primaryV2ButtonVariants,
  primaryV2CompoundVariants,
  secondaryButtonVariants,
  secondaryCompoundVariants,
  secondaryV2ButtonVariants,
  secondaryV2CompoundVariants,
  tertiaryButtonVariants,
  tertiaryCompoundVariants,
} from "./variants";
import {
  tertiaryV2ButtonVariants,
  tertiaryV2CompoundVariants,
} from "./variants/tertiaryV2";

export const iconVariants = cva("h-full fill-current", {
  variants: {
    size: {
      small: "w-3",
      medium: "w-4",
      big: "w-5",
    },
  },
});

export const buttonVariants = cva([], {
  variants: {
    intent: {
      primary: primaryButtonVariants.base,
      secondary: secondaryButtonVariants.base,
      tertiary: tertiaryButtonVariants.base,
      primaryV2: primaryV2ButtonVariants.base,
      secondaryV2: secondaryV2ButtonVariants.base,
      tertiaryV2: tertiaryV2ButtonVariants.base,
    },
    size: {
      small: [],
      medium: [],
      big: [],
    },
    background: {
      dark: "",
      light: "",
    },
  },
  compoundVariants: [
    ...primaryCompoundVariants,
    ...secondaryCompoundVariants,
    ...tertiaryCompoundVariants,
    ...primaryV2CompoundVariants,
    ...secondaryV2CompoundVariants,
    ...tertiaryV2CompoundVariants,
  ],
  defaultVariants: {
    intent: "primary",
    size: "medium",
    background: "light",
  },
});
