import React, { SVGProps } from "react";

const Box = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="0 0 20 22"
    {...props}
  >
    <g clipPath="url(#clip0_4698_42037)">
      <path d="M10.5264 22L19.9995 16.7211V6.16028L10.5264 11.4392V22Z" />
      <path d="M0 16.7211L9.4731 22V11.4392L0 6.16028V16.7211Z" />
      <path d="M9.99947 0L0.526367 5.2789L9.99947 10.5578L19.4726 5.2789L9.99947 0Z" />
    </g>
    <defs>
      <clipPath id="clip0_4698_42037">
        <rect width="20" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Box;
