import React, { SVGProps } from "react";

const Diamond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="#1F2621"
    {...props}
  >
    <g clipPath="url(#clip0_4698_42077)">
      <path d="M19.9995 16.9808V7.14685L14.335 16.9808H19.9995Z" />
      <path d="M13.1155 16.9807L19.6139 5.70251H15.087L12.2041 16.9807H13.1155Z" />
      <path d="M15.771 3.03125L15.3564 4.64673H18.559L15.771 3.03125Z" />
      <path d="M11.4414 22L18.2927 18.0366H13.7262L11.4414 22Z" />
      <path d="M14.8207 2.48425L10.5264 0V4.64668H14.2669L14.8207 2.48425Z" />
      <path d="M10.5264 5.70251V16.9807H11.115L13.9979 5.70251H10.5264Z" />
      <path d="M11.4766 19.8249L12.5082 18.0366H11.9354L11.4766 19.8249Z" />
      <path d="M10.5264 19.2795L10.846 18.0366H10.5264V19.2795Z" />
      <path d="M9.47321 19.251V18.0366H9.16309L9.47321 19.251Z" />
      <path d="M5.73995 4.64668H9.47254V0L5.18457 2.4795L5.73995 4.64668Z" />
      <path d="M1.70898 18.0366L8.55867 22L6.27702 18.0366H1.70898Z" />
      <path d="M8.07484 18.0366H7.49414L8.53686 19.8471L8.07484 18.0366Z" />
      <path d="M7.80346 16.9807L4.92213 5.70251H0.385742L6.88416 16.9807H7.80346Z" />
      <path d="M0 7.14685V16.9808H5.66614L0 7.14685Z" />
      <path d="M4.23887 3.02966L1.44141 4.64673H4.65185L4.23887 3.02966Z" />
      <path d="M8.89207 16.9807H9.47277V5.70251H6.01074L8.89207 16.9807Z" />
    </g>
    <defs>
      <clipPath id="clip0_4698_42077">
        <rect width="20" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Diamond;
