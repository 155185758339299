import { RootNode } from "@layout/navigation/Nav.types";
import { cn } from "@utils/tailwind";
import { FC, HTMLAttributes, MouseEventHandler, ReactNode } from "react";
import styles from "./DropdownContainer.module.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  id: string;
  node: RootNode;
  children: ReactNode | undefined;
  closeDesktopDropdown: MouseEventHandler<HTMLDivElement>;
}

export const DropdownContainer: FC<Props> = ({
  id,
  node,
  children,
  closeDesktopDropdown,
}) => (
  <div
    id={id}
    data-nav-item="menu-dropdown"
    className={cn(
      "hidden w-full bg-white pb-5 lg:fixed lg:left-0 lg:top-[--nav-height] lg:py-14",
      "border-b border-b-grey-scale-light-gray group-last/mainlink:border-none lg:border-none",
      "transition-[top]",
      node.layout === "two-sided" ? styles.lightGray : ""
    )}
  >
    <div
      className="absolute inset-x-0 top-0 hidden h-[calc(100dvh-var(--nav-height))] transition-[height] lg:block"
      onClick={closeDesktopDropdown}
    />
    <div className="relative flex flex-wrap lg:container-flexible">
      {children}
    </div>
  </div>
);
