import Icon from "@atoms/icon/Icon";
import { Popover, PopoverContent, PopoverTrigger } from "@atoms/popover";
import Typography from "@atoms/typography/Typography";
import { cn } from "@utils/tailwind";
import { FC } from "react";

export const NewsletterPopover: FC<{
  label: string | undefined;
  content: string;
  darkTheme?: boolean;
}> = ({ label, content, darkTheme }) => (
  <Popover>
    <PopoverTrigger
      type="button"
      className={cn(
        "flex items-center gap-1.5 opacity-75 xl:mb-2",
        darkTheme ? "text-blue-light" : "text-grey-scale-off-black"
      )}
    >
      <Icon name="Info" />
      <Typography
        tag="span"
        variant="xs"
        className="text-start underline underline-offset-2"
      >
        {label}
      </Typography>
    </PopoverTrigger>
    <PopoverContent
      side="bottom"
      align="start"
      className="border-grey-scale-off-black bg-grey-scale-off-black text-white"
    >
      <Typography
        wysiwygStyling
        tag="p"
        variant="xs"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </PopoverContent>
  </Popover>
);
