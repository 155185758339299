import Icon from "@atoms/icon/Icon";
import { NavLink } from "@layout/navigation/Nav.types";
import { isExternal } from "@utils/helpers/isExternal";
import Link from "next/link";
import { FC } from "react";
import { twMerge } from "tailwind-merge";
import styles from "./BlackLink.module.scss";

interface BlackLinkProps {
  item: NavLink;
  className?: string;
}

export const BlackLink: FC<BlackLinkProps> = ({ className, item }) => (
  <Link
    role="menuitem"
    href={item.href}
    target={item.target}
    className={twMerge(
      "text-lg font-medium lg:text-sm lg:font-normal",
      "capitalize focus:outline-disabled",
      "block text-nowrap py-2 lg:inline-block",
      styles.blackLink,
      className
    )}
  >
    <span>
      {item.name}
      <span className={isExternal(item.href) ? "inline-block pl-2" : "hidden"}>
        <Icon width={10} height={10} name="ArrowSkewThin" />
      </span>
    </span>
  </Link>
);
