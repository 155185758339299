import { ButtonCompoundVariant, ButtonVariantStyles } from "../Button.types";

export const tertiaryButtonVariants: ButtonVariantStyles = {
  base: [
    "font-semibold",
    "text-sm",
    "rounded-md",
    "bg-transparent",
    "focus:bg-transparent",
    "gap-3",
    "py-4",
    "px-7",
  ],
  light: [
    "text-grey-scale-off-black",
    "border-content-primary-on-light",
    "hover:text-content-primary-on-dark",
    "hover:bg-content-primary-on-light",
    "hover:border-content-primary-on-light",
    "active:bg-green-off-black",
    "active:text-content-primary-on-dark",
    "active:border-green-off-black",
    "focus:text-content-primary-on-light",
  ],
  dark: [
    "text-content-primary-on-dark",
    "border-content-primary-on-dark",
    "hover:text-content-primary-on-light",
    "hover:bg-content-primary-on-dark",
    "hover:border-content-primary-on-dark",
    "active:bg-grey-scale-light-gray",
    "active:text-content-primary-on-light",
    "active:border-grey-scale-light-gray",
    "focus:text-content-primary-on-dark",
  ],
};

const { light, dark } = tertiaryButtonVariants;

export const tertiaryCompoundVariants: ButtonCompoundVariant[] = [
  {
    intent: "tertiary",
    background: "light",
    className: light,
  },
  {
    intent: "tertiary",
    background: "dark",
    className: dark,
  },
];
