import { SVGProps } from "react";

const ArrowLeftShort = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="icon"
      d="M2.77552 3.29912H9V4.70088H2.77552L5.16278 7.00881L4.13751 8L0 4L4.13751 0L5.16278 0.991195L2.77552 3.29912Z"
    />
  </svg>
);

export default ArrowLeftShort;
