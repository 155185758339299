import React, { SVGProps } from "react";

const Error = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    viewBox="0 0 10 10"
    fill="#FF7C3F"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M5 3.093 8.093 0 10 1.907 6.907 5 10 8.093 8.093 10 5 6.907 1.907 10 0 8.093 3.093 5 0 1.907 1.907 0 5 3.093Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Error;
