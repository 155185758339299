import React, { SVGProps } from "react";

const Infrastructure = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.46 63.46" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={34.52}
        x2={36.01}
        y1={22.82}
        y2={51.4}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.15} stopColor="#272723" stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="b"
        x1={52.33}
        x2={34.63}
        y1={28.43}
        y2={15.56}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.17} stopColor="#fe9c5a" />
        <stop offset={0.42} stopColor="#fead6d" />
        <stop offset={0.71} stopColor="#fdc88c" />
        <stop offset={1} stopColor="#fdeab3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={42.2}
        x2={36.66}
        y1={21.68}
        y2={30.99}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.75} stopColor="#ff9754" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={29.01}
        x2={20.32}
        y1={34.16}
        y2={55.67}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#272723" stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
    </defs>
    <rect
      width={28.25}
      height={28.25}
      x={20.24}
      y={23.02}
      rx={2.5}
      ry={2.5}
      style={{
        fill: "#14e37c",
      }}
    />
    <path
      d="M38.6 51.26H23.3c-1.34 0-2.29-1.3-1.9-2.57l13.27-25.88h14.44L40.5 49.86c-.26.83-1.03 1.4-1.9 1.4Z"
      style={{
        fill: "url(#a)",
        opacity: 0.3,
      }}
    />
    <path
      d="M51.26 39.19v10.66"
      style={{
        fill: "none",
        stroke: "#009c3f",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: "2.3px",
      }}
    />
    <path
      d="M50.77 11.78 40.65 9.77c-.88-.17-1.78.13-2.37.81l-6.8 7.76c-.59.67-.77 1.61-.49 2.45l3.32 9.77c.29.85 1 1.47 1.88 1.65l10.12 2.01c.88.17 1.78-.13 2.37-.81l6.8-7.76c.59-.67.77-1.61.49-2.45l-3.32-9.77c-.29-.85-1-1.47-1.88-1.65Z"
      style={{
        fill: "url(#b)",
      }}
    />
    <path
      d="M43.68 22.42a2.523 2.523 0 0 0-1.93-1.57l-9.41-1.57c-1.2 0-1.58.79-1.23 1.82l3.22 9.45a2.53 2.53 0 0 0 1.89 1.66l9.8 1.94c1.07.21 1.96-1.04 1.36-2.25l-3.69-9.49Z"
      style={{
        fill: "url(#c)",
      }}
    />
    <path
      d="M24.65 53.94H9.35c-1.34 0-2.29-1.3-1.9-2.57l4.78-15.48c.26-.83 1.03-1.4 1.9-1.4h15.3c1.34 0 2.29 1.3 1.9 2.57l-4.78 15.48c-.26.83-1.03 1.4-1.9 1.4Z"
      style={{
        fill: "#d4e6e8",
      }}
    />
    <path
      d="M24.65 53.94h-8.62l8.79-16.68c.9-1.71 2.67-2.78 4.61-2.78 1.34 0 2.29 1.3 1.9 2.57l-4.78 15.48c-.26.83-1.03 1.4-1.9 1.4Z"
      style={{
        opacity: 0.3,
        fill: "url(#d)",
      }}
    />
  </svg>
);

export default Infrastructure;
