import { tw } from "@utils/tailwind";
import { ButtonCompoundVariant, ButtonVariantStyles } from "../Button.types";

export const primaryV2ButtonVariants: ButtonVariantStyles = {
  base: tw`
    rounded-full
    border-green-accent-two
    bg-green-accent-two
    font-termina font-medium text-content-primary-on-light
    hover:border-green-accent-one hover:bg-green-accent-one
    focus:bg-green-accent-two
  `,
  light: ["focus:border-content-primary-on-light"],
  dark: ["focus:border-content-primary-on-dark"],
};

const { light, dark } = primaryV2ButtonVariants;

export const primaryV2CompoundVariants: ButtonCompoundVariant[] = [
  {
    intent: "primaryV2",
    background: "light",
    className: light,
  },
  {
    intent: "primaryV2",
    background: "dark",
    className: dark,
  },
  {
    intent: "primaryV2",
    size: "big",
    className: "xl:px-12 xl:py-4 px-9 py-3 xl:text-base text-xs",
  },
  {
    intent: "primaryV2",
    size: "medium",
    className: "xl:px-12 xl:py-4 px-9 py-3 xl:text-base text-xs",
  },
  {
    intent: "primaryV2",
    size: "small",
    className: "px-9 py-3 text-xs",
  },
];
