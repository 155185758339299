import { NodeWithHorizontalLayout } from "@layout/navigation/Nav.types";
import { FC } from "react";
import { GreenLink } from "../green-link/GreenLink";

interface Props {
  node: NodeWithHorizontalLayout;
}

export const DropdownColumnsDescriptive: FC<Props> = ({ node }) => (
  <div className="flex basis-full">
    <div
      className="hidden w-[calc(125px+64px+16px)] lg:block"
      data-name="placeholder"
    />
    <div className="w-full lg:w-[calc(100%-(125px+64px+16px))] xl:w-[calc(100%-(125px+64px)*2)]">
      <div className="mb-6 hidden text-nowrap border-b border-grey-scale-gray pb-4 text-xs uppercase text-grey-scale-gray opacity-50 lg:block">
        {node.childNode.name}
      </div>
      <ul
        role="menu"
        className="ml-10 lg:ml-0 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:gap-y-9 2xl:grid-cols-5"
      >
        {node.childNode.children.map((link, idx) => (
          <li key={idx} role="presentation">
            <GreenLink link={link} />
          </li>
        ))}
      </ul>
    </div>
  </div>
);
