import { SVGProps } from "react";

const TisaxCertification = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="267"
    height="38"
    viewBox="0 0 267 38"
    {...props}
  >
    <g>
      <path d="M62.789 30.1481C61.8608 30.1481 60.975 30.1464 60.0885 30.1481C59.0886 30.1505 58.0887 30.1659 57.0896 30.1529C56.8063 30.1489 56.6805 30.2435 56.573 30.4959C55.7697 32.3752 54.9423 34.2448 54.1366 36.1225C53.9774 36.4938 53.7483 36.6532 53.3191 36.654C51.2369 36.6596 49.1546 36.6928 47.0723 36.7171C46.5382 36.7235 46.4848 36.6224 46.7306 36.1516C48.1547 33.4229 49.5762 30.6925 50.9927 27.9606C52.3409 25.3613 53.6991 22.766 55.024 20.1554C56.588 17.0732 58.1262 13.9788 59.661 10.8828C59.8477 10.5074 60.0793 10.3472 60.5218 10.3432C63.3398 10.3173 66.157 10.2655 68.9751 10.2194C69.4559 10.2113 69.5275 10.2744 69.5442 10.7412C69.7183 15.5498 69.8883 20.3585 70.0708 25.1663C70.1899 28.3181 70.3258 31.4691 70.4583 34.6202C70.4799 35.1314 70.5099 35.6435 70.5516 36.154C70.5807 36.5051 70.4208 36.6426 70.0708 36.6467C67.7385 36.675 65.4071 36.7057 63.0748 36.7446C62.6582 36.7519 62.6424 36.5003 62.6507 36.2098C62.6965 34.6202 62.744 33.0305 62.7865 31.4408C62.7965 31.0517 62.7882 30.6618 62.7882 30.1473L62.789 30.1481ZM59.0019 24.9115C59.2227 24.9236 59.3694 24.9382 59.5152 24.9382C60.4576 24.9406 61.4008 24.9228 62.3432 24.9471C62.7357 24.9576 62.869 24.8556 62.8649 24.4536C62.8449 22.2183 62.8549 19.9823 62.8549 17.747C62.8549 17.2139 62.8549 16.6808 62.8549 16.1485C62.8307 16.142 62.8065 16.1347 62.7832 16.1283C61.5316 19.035 60.2801 21.9417 59.0019 24.9115Z" />
      <path d="M86.3219 17.6305C87.1743 16.2058 87.98 14.871 88.7733 13.5297C89.2433 12.7353 89.6974 11.9311 90.149 11.127C90.3265 10.8107 90.579 10.6659 90.9523 10.657C92.9362 10.6109 94.9202 10.551 96.905 10.5065C97.0525 10.5033 97.2025 10.585 97.3516 10.627C97.3091 10.7678 97.3008 10.9328 97.2183 11.0461C94.3636 14.9454 91.503 18.8415 88.635 22.7311C88.4317 23.007 88.3817 23.2368 88.5017 23.5749C89.914 27.5349 91.3055 31.5014 92.7046 35.4655C92.7946 35.7203 92.9021 35.9687 93.0029 36.2203C93.1554 36.5989 93.0887 36.6992 92.6604 36.7048C91.6747 36.7178 90.6889 36.7162 89.7032 36.7364C88.2742 36.7655 86.8452 36.8052 85.4161 36.8488C84.7845 36.8683 84.7254 36.8213 84.5771 36.2211C84.0379 34.0473 83.4905 31.8752 82.9447 29.7022C82.9231 29.6157 82.8814 29.534 82.8189 29.3722C82.5406 29.8309 82.2931 30.2297 82.0556 30.6342C80.9566 32.5078 79.8559 34.3806 78.7676 36.2599C78.5902 36.5665 78.3485 36.7 77.9985 36.7073C75.9029 36.751 73.8081 36.8027 71.7125 36.8408C71.5634 36.8432 71.4125 36.7574 71.2617 36.7129C71.3184 36.5746 71.3509 36.4201 71.4359 36.3012C73.6865 33.1267 75.9454 29.9571 78.1969 26.7834C78.9135 25.7738 79.6092 24.7504 80.3275 23.7416C80.4841 23.5215 80.5191 23.333 80.4291 23.0725C79.0618 19.1125 77.7061 15.1493 76.3479 11.186C76.2087 10.7799 76.2829 10.6772 76.7178 10.6594C79.1309 10.5591 81.544 10.4588 83.9571 10.3576C84.5321 10.3334 84.5704 10.356 84.7037 10.9255C85.1578 12.8558 85.6044 14.7877 86.0594 16.7179C86.1244 16.9922 86.2152 17.2608 86.3235 17.6321L86.3219 17.6305Z" />
      <path d="M37.6516 37.1734C34.9702 37.124 32.7812 36.8384 30.7131 35.9453C30.1123 35.6856 30.0657 35.5707 30.2307 34.9446C30.6439 33.3784 31.0481 31.8097 31.4689 30.2452C31.5055 30.1092 31.6147 29.928 31.7297 29.8941C31.8288 29.8649 32.018 29.9871 32.1096 30.0915C33.2395 31.3729 34.6285 32.1366 36.3984 32.1819C38.9423 32.2466 39.9663 29.7169 38.8898 28.0043C38.4173 27.2519 37.7807 26.5934 37.1916 25.9146C36.5834 25.214 35.9476 24.5361 35.3151 23.8558C32.9187 21.2767 32.7321 18.4007 34.1003 15.3201C35.311 12.5938 37.4541 10.9669 40.433 10.4225C43.3952 9.88124 46.3224 10.0414 49.1538 11.1279C49.4887 11.2565 49.9053 11.3172 50.0387 11.7266C50.0845 11.8681 50.1062 12.0445 50.0628 12.182C49.5545 13.7782 49.0329 15.3702 48.513 16.9623C48.383 17.3612 48.0889 17.4194 47.7756 17.1169C47.134 16.4964 46.474 15.8969 45.6116 15.5693C44.725 15.2327 43.821 15.1437 42.9485 15.5814C41.667 16.2237 41.2154 17.7293 42.0195 18.9371C42.5261 19.6975 43.2177 20.3423 43.821 21.0429C44.7809 22.1577 45.8716 23.194 46.6657 24.4115C48.2913 26.9032 48.1272 29.5591 46.8798 32.1431C45.5033 34.9931 43.0002 36.4145 39.9022 36.9428C39.0173 37.0941 38.1115 37.1281 37.6507 37.1734H37.6516Z" />
      <path d="M6.06013 16.0403C5.05107 16.0403 4.082 16.0363 3.11293 16.0419C2.39133 16.046 1.66974 16.0678 0.947311 16.071C0.519021 16.0727 0.432363 15.9562 0.544851 15.5525C0.965643 14.0437 1.3931 12.5374 1.80722 11.0278C1.90472 10.6726 2.13219 10.5812 2.48382 10.5812C6.31677 10.5885 10.1489 10.5958 13.9818 10.5812C15.7858 10.5739 17.5898 10.5181 19.3946 10.4866C20.1995 10.472 20.2254 10.5092 20.0246 11.2462C19.6379 12.6652 19.2471 14.0842 18.8805 15.508C18.778 15.9068 18.5739 16.0484 18.1506 16.0419C16.6941 16.0209 15.2375 16.0338 13.7144 16.0338C13.526 16.7635 13.3127 17.5119 13.1394 18.2691C11.7704 24.2669 10.408 30.2664 9.05066 36.2667C8.97401 36.6056 8.79736 36.7238 8.44489 36.727C6.14096 36.748 3.83619 36.7852 1.53225 36.8176C1.07313 36.8241 0.978141 36.7221 1.08896 36.2861C1.74806 33.6828 2.4305 31.0843 3.07043 28.4769C4.05533 24.4667 5.01274 20.4509 5.98097 16.4367C6.0118 16.3097 6.03263 16.1803 6.06013 16.0419V16.0403Z" />
      <path d="M23.3376 11.7352C25.4149 13.9154 27.8396 14.4979 30.701 13.4494C30.691 13.57 30.6943 13.6711 30.6735 13.7674C29.5311 18.8883 28.3779 24.0068 27.2472 29.1301C26.7297 31.4746 26.2523 33.8271 25.7665 36.1772C25.6957 36.5202 25.5449 36.6828 25.1591 36.6877C22.786 36.7184 20.4137 36.7686 18.0414 36.8131C17.554 36.822 17.4782 36.7241 17.5857 36.2646C19.4646 28.2532 21.3411 20.2409 23.2184 12.2287C23.2526 12.0847 23.2884 11.9407 23.3384 11.7344L23.3376 11.7352Z" />
      <path d="M28.5188 12.3873C25.14 12.3954 22.3761 9.76052 22.3711 6.52617C22.3653 3.16158 25.0617 0.5 28.4738 0.5C31.8785 0.5 34.6107 3.15349 34.614 6.46307C34.6174 9.71036 31.871 12.3792 28.5188 12.3873Z" />
      <path d="M103.965 13.2392C103.971 14.7398 102.772 15.9193 101.236 15.9234C99.7051 15.9274 98.4853 14.7754 98.4728 13.3128C98.4594 11.7749 99.6418 10.5978 101.203 10.5938C102.764 10.5905 103.959 11.7344 103.965 13.2383V13.2392ZM103.698 13.2731C103.698 11.9165 102.607 10.8429 101.23 10.8437C99.8643 10.8445 98.7277 11.9456 98.7319 13.2618C98.7361 14.591 99.8618 15.6718 101.237 15.6661C102.602 15.6605 103.697 14.595 103.698 13.2731Z" />
      <path d="M102.045 13.1797C102.162 13.4992 102.275 13.7654 102.354 14.0404C102.431 14.3098 102.473 14.5889 102.531 14.864C101.926 15.0395 101.824 14.9821 101.726 14.4093C101.694 14.2233 101.677 14.034 101.627 13.8528C101.534 13.5275 101.162 13.3212 100.847 13.4353C100.768 13.4636 100.694 13.6109 100.689 13.7063C100.672 14.0938 100.682 14.4821 100.682 14.9012H100.044C100.044 14.7313 100.044 14.5509 100.044 14.3697C100.044 13.5478 100.053 12.7258 100.039 11.9047C100.035 11.645 100.113 11.5415 100.392 11.556C100.765 11.5754 101.141 11.556 101.516 11.5625C102.07 11.5722 102.397 11.7801 102.517 12.1878C102.637 12.5956 102.476 12.9103 102.044 13.1797H102.045ZM101.203 12.0398C100.685 12.0374 100.696 12.0382 100.679 12.4524C100.667 12.7509 100.677 12.9653 101.099 12.9386C101.653 12.9038 101.93 12.7792 101.905 12.4629C101.871 12.026 101.53 12.0358 101.203 12.0398Z" />
      <path d="M124.229 10.9062L129.191 19.9904L134.154 10.9062H135.583L129.883 21.3479H128.47L122.77 10.9062H124.229H124.229Z" />
      <path d="M137.133 11.1732C137.133 10.7258 137.517 10.3828 138.024 10.3828C138.53 10.3828 138.914 10.7258 138.914 11.1732C138.914 11.6206 138.53 11.9636 138.024 11.9636C137.517 11.9636 137.133 11.6052 137.133 11.1732ZM138.653 21.3463H137.394V13.2167H138.653V21.3463Z" />
      <path d="M141.742 21.3485V13.2189H143.002V15.2179C143.678 13.8459 145.353 13.0547 147.166 13.0547V14.1582C145.261 14.1582 143.632 14.9785 143.002 16.6045V21.3477H141.742V21.3485Z" />
      <path d="M154.621 20.8826C153.96 21.33 153.131 21.643 152.193 21.643C150.688 21.643 149.444 20.8672 149.444 19.2864V14.2893H147.754V13.215H149.444V11.3203H150.704V13.215H154.56V14.2893H150.704V19.1077C150.704 20.1068 151.487 20.5549 152.409 20.5549C153.07 20.5549 153.623 20.3163 154.177 20.0032L154.622 20.8834L154.621 20.8826Z" />
      <path d="M165.451 13.2196V21.3491H164.191V19.6632C163.408 20.8864 161.994 21.6468 160.197 21.6468C157.6 21.6468 156.355 20.0361 156.355 17.813V13.2188H157.615V17.724C157.615 19.5588 158.553 20.5579 160.443 20.5579C162.071 20.5579 163.423 19.8266 164.191 18.3202V13.2188H165.451V13.2196Z" />
      <path d="M176.913 19.0214C176.913 20.4833 175.392 21.662 172.534 21.662C170.613 21.662 168.938 21.0204 167.648 19.917L168.448 19.1856C169.538 20.0804 170.814 20.6475 172.565 20.6475C174.317 20.6475 175.669 20.1257 175.669 19.1411C175.669 18.1566 174.424 17.8581 172.289 17.6793C170.077 17.5005 167.972 17.0378 167.972 15.3672C167.972 13.6966 169.816 12.9062 172.289 12.9062C173.979 12.9062 175.561 13.5623 176.483 14.2937L175.684 15.114C174.901 14.4579 173.779 13.9353 172.227 13.9207C170.952 13.9062 169.216 14.2039 169.216 15.2928C169.216 16.3072 170.721 16.5014 172.55 16.6349C175.069 16.829 176.913 17.4406 176.913 19.0214H176.913Z" />
      <path d="M179.156 21.3473V10.3086H180.416V21.3473H179.156Z" />
      <path d="M192.028 16.8408V19.7645C192.028 20.3761 192.136 20.8679 192.428 21.3452H191.091C190.845 20.9423 190.738 20.5095 190.738 20.0476V20.0177C190.077 20.8979 188.755 21.6437 186.943 21.6437C185.131 21.6437 182.672 20.9278 182.672 18.7645C182.672 16.6013 185.115 15.8853 186.943 15.8853C188.771 15.8853 190.078 16.6159 190.738 17.5114V16.84C190.738 14.8709 189.585 13.9761 187.281 13.9761C185.944 13.9761 184.93 14.3337 183.993 14.9162L183.408 14.0214C184.392 13.3799 185.974 12.918 187.496 12.918C190.307 12.918 192.028 14.2601 192.028 16.8408H192.028ZM190.769 18.6157C190.046 17.348 188.464 16.9006 187.051 16.9006C185.822 16.9006 183.916 17.2436 183.916 18.7654C183.916 20.2871 185.821 20.6301 187.051 20.6301C188.464 20.6301 190.047 20.1673 190.769 18.915V18.6165V18.6157Z" />
      <path d="M204.813 17.2894C204.813 19.9145 203.062 21.645 200.265 21.645C198.421 21.645 196.993 20.9137 196.225 19.7803V21.3465H194.965V10.3086H196.225V14.7839C196.993 13.6505 198.422 12.9192 200.265 12.9192C203.062 12.9192 204.813 14.6642 204.813 17.2902V17.2894ZM203.538 17.2894C203.538 15.186 202.247 14.0073 199.943 14.0073C198.391 14.0073 197.085 14.5445 196.225 15.9909V18.5716C197.085 20.0189 198.391 20.5553 199.943 20.5553C202.247 20.5553 203.538 19.3766 203.538 17.2886V17.2894Z" />
      <path d="M211.926 11.1732C211.926 10.7258 212.31 10.3828 212.817 10.3828C213.323 10.3828 213.707 10.7258 213.707 11.1732C213.707 11.6206 213.323 11.9636 212.817 11.9636C212.31 11.9636 211.926 11.6052 211.926 11.1732ZM213.446 21.3463H212.187V13.2167H213.446V21.3463Z" />
      <path d="M224.955 19.0214C224.955 20.4833 223.435 21.662 220.577 21.662C218.656 21.662 216.981 21.0204 215.691 19.917L216.49 19.1856C217.581 20.0804 218.857 20.6475 220.608 20.6475C222.36 20.6475 223.712 20.1257 223.712 19.1411C223.712 18.1566 222.467 17.8581 220.332 17.6793C218.119 17.5005 216.015 17.0378 216.015 15.3672C216.015 13.6966 217.859 12.9062 220.332 12.9062C222.022 12.9062 223.604 13.5623 224.526 14.2937L223.727 15.114C222.944 14.4579 221.822 13.9353 220.27 13.9207C218.995 13.9062 217.259 14.2039 217.259 15.2928C217.259 16.3072 218.764 16.5014 220.593 16.6349C223.112 16.829 224.956 17.4406 224.956 19.0214H224.955Z" />
      <path d="M134.615 28.7893H129.914V37.1729H127.456V28.7893H122.785V26.7305H134.616V28.7893H134.615Z" />
      <path d="M136.305 37.1721V26.7305H138.763V37.1721H136.305Z" />
      <path d="M153.067 34.0101C153.067 36.069 151.054 37.5009 147.244 37.5009C144.524 37.5009 142.312 36.755 140.668 35.3975L142.081 33.7714C143.403 34.9501 145.108 35.5019 147.305 35.5019C149.502 35.5019 150.593 35.099 150.593 34.2342C150.593 33.3694 149.486 33.1307 147.013 32.9212C143.94 32.668 141.083 32.0111 141.083 29.7734C141.083 27.5357 143.479 26.4023 146.798 26.4023C149.149 26.4023 151.177 26.9694 152.544 27.9985L151.192 29.6545C150.009 28.7743 148.55 28.4167 146.798 28.4014C145.338 28.3868 143.556 28.64 143.556 29.5946C143.556 30.4894 145.093 30.5792 147.228 30.7434C150.654 31.012 153.067 31.7134 153.067 34.0101Z" />
      <path d="M163.591 34.9644H157.092L155.94 37.1721H153.312L159.013 26.7305H161.702L167.402 37.1721H164.744L163.591 34.9644ZM162.562 33.0252L160.334 28.774L158.106 33.0252H162.562H162.562Z" />
      <path d="M175.328 31.8619L180.275 37.1721H177.263L173.791 33.4726L170.319 37.1721H167.461L172.393 31.8918L167.538 26.7305H170.549L173.944 30.3402L177.309 26.7305H180.182L175.327 31.8619H175.328Z" />
      <path d="M195.64 33.7407L197.883 34.8296C196.869 36.306 194.795 37.5001 191.984 37.5001C187.944 37.5001 185.316 35.4266 185.316 31.9512C185.316 28.4758 187.944 26.4023 192.092 26.4023C194.795 26.4023 196.869 27.611 197.853 29.0575L195.595 30.1464C194.826 28.983 193.659 28.4612 192.061 28.4612C189.51 28.4612 187.82 29.6545 187.82 31.952C187.82 34.2496 189.51 35.4428 192.061 35.4428C193.659 35.4428 194.842 34.9356 195.64 33.7423V33.7407Z" />
      <path d="M204.581 35.7401C206.04 35.7401 206.962 35.3671 207.838 34.6811L209.113 36.0386C207.929 37.0531 206.409 37.5004 204.457 37.5004C200.969 37.5004 198.957 35.7999 198.957 33.0704C198.957 30.3409 200.954 28.625 204.288 28.625C207.361 28.625 209.419 30.1467 209.419 33.055C209.419 33.3236 209.404 33.4579 209.358 33.5922H201.245C201.46 35.0686 202.612 35.7401 204.579 35.7401H204.581ZM201.354 32.115L207.146 32.0851C206.777 30.9064 205.81 30.3846 204.304 30.3846C202.798 30.3846 201.692 30.9517 201.354 32.115Z" />
      <path d="M211.234 37.1716V28.9522H213.616V30.6527C214.307 29.415 215.766 28.7734 217.395 28.7734V30.7571C215.551 30.7571 214.123 31.4585 213.616 32.905V37.1708H211.234V37.1716Z" />
      <path d="M225.477 36.6942C224.755 37.1715 223.695 37.4846 222.697 37.4846C220.915 37.4846 219.532 36.5446 219.532 34.6952V30.6826H217.965V28.9522H219.532V27.043H221.914V28.9522H225.402V30.6826H221.914V34.2923C221.914 35.128 222.559 35.56 223.404 35.56C223.988 35.56 224.511 35.3513 224.987 35.1272L225.478 36.6934L225.477 36.6942Z" />
      <path d="M226.77 26.8037C226.77 26.1023 227.369 25.625 228.214 25.625C229.058 25.625 229.627 26.1023 229.627 26.8037C229.627 27.5051 229.043 27.967 228.214 27.967C227.384 27.967 226.77 27.4752 226.77 26.8037ZM229.428 37.1709H227.046V28.9516H229.428V37.1709Z" />
      <path d="M234.818 28.4896V28.9524H238.366V30.6828H234.878V37.1717H232.497V30.6828H230.93V28.9524H232.497V28.7582C232.497 26.7446 233.957 25.82 235.831 25.82C236.707 25.82 237.767 26.0287 238.458 26.3717L237.981 27.7891C237.413 27.4905 236.875 27.3716 236.353 27.3716C235.416 27.3716 234.817 27.6847 234.817 28.4904L234.818 28.4896ZM239.611 26.8037C239.611 26.1023 240.21 25.625 241.055 25.625C241.9 25.625 242.469 26.1023 242.469 26.8037C242.469 27.5051 241.884 27.967 241.055 27.967C240.226 27.967 239.611 27.4752 239.611 26.8037ZM239.888 28.9516H242.269V37.1709H239.888V28.9516Z" />
      <path d="M249.768 35.7401C251.228 35.7401 252.15 35.3671 253.025 34.6811L254.3 36.0386C253.117 37.0531 251.596 37.5004 249.645 37.5004C246.157 37.5004 244.145 35.7999 244.145 33.0704C244.145 30.3409 246.142 28.625 249.476 28.625C252.549 28.625 254.607 30.1467 254.607 33.055C254.607 33.3236 254.592 33.4579 254.545 33.5922H246.433C246.648 35.0686 247.8 35.7401 249.766 35.7401H249.768ZM246.542 32.115L252.334 32.0851C251.965 30.9064 250.997 30.3846 249.491 30.3846C247.986 30.3846 246.879 30.9517 246.542 32.115Z" />
      <path d="M264.117 30.2943V26.1328H266.499V37.1715H264.117V35.8439C263.426 36.7541 262.166 37.4846 260.43 37.4846C257.741 37.4846 255.867 35.7541 255.867 33.0691C255.867 30.3841 257.742 28.6536 260.43 28.6536C262.167 28.6536 263.426 29.385 264.117 30.2943ZM264.102 34.2171V31.9195C263.365 30.9795 262.274 30.5774 261.029 30.5774C259.185 30.5774 258.14 31.4576 258.14 33.0683C258.14 34.679 259.185 35.5592 261.029 35.5592C262.274 35.5592 263.365 35.1563 264.102 34.2171Z" />
    </g>
  </svg>
);

export default TisaxCertification;
