import React, { SVGProps } from "react";

const FloatingArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="16"
    viewBox="0 0 6 11"
    fill="black"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6484 5.59914L5.63086 9.7655L4.85859 10.5734L0.103871 5.59914L4.85859 0.624846L5.63086 1.43277L1.6484 5.59914Z"
    />
  </svg>
);
export default FloatingArrowLeft;
