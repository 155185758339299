import React, { SVGProps } from "react";

const Scala = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 63.46 63.46"
    {...props}
  >
    <defs>
      <linearGradient
        id="c"
        x1={27.7}
        x2={35.35}
        y1={30.57}
        y2={13.39}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.17} stopColor="#fe9c5a" />
        <stop offset={0.42} stopColor="#fead6d" />
        <stop offset={0.71} stopColor="#fdc88c" />
        <stop offset={1} stopColor="#fdeab3" />
      </linearGradient>
      <linearGradient xlinkHref="#c" id="d" x2={35.35} y1={40.08} y2={22.9} />
      <linearGradient
        xlinkHref="#c"
        id="e"
        x1={27.7}
        x2={35.35}
        y1={49.59}
        y2={32.42}
      />
      <linearGradient
        id="f"
        x1={42}
        x2={21.39}
        y1={24.19}
        y2={20.92}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.25} stopColor="#ff9754" stopOpacity={0.72} />
        <stop offset={0.89} stopColor="#ff9754" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        xlinkHref="#f"
        id="g"
        x1={42}
        x2={21.39}
        y1={33.7}
        y2={30.44}
      />
      <linearGradient xlinkHref="#f" id="h" y1={43.21} y2={39.95} />
      <linearGradient
        id="i"
        x1={54.3}
        x2={30.97}
        y1={43.62}
        y2={45.25}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.14} stopOpacity={0} />
        <stop offset={0.91} />
      </linearGradient>
      <style>
        {
          ".p{stroke:#14e37c;fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2.3px}"
        }
      </style>
    </defs>
    <g id="b">
      <path
        d="M47.57 17.3c3.25 3.74 5.22 8.62 5.22 13.96"
        style={{
          stroke: "#ff9754",
          fill: "none",
          strokeLinecap: "round",
          strokeMiterlimit: 10,
          strokeWidth: "2.3px",
        }}
      />
      <path d="M14.69 44.26a21.184 21.184 0 0 1-4.44-13c0-11.75 9.52-21.27 21.27-21.27 2.49 0 4.88.43 7.1 1.21M26.14 51.84a21.23 21.23 0 0 1-11.45-7.58" />
      <path
        d="m41.24 23.76-18.87 4.16c-.8.18-1.57-.44-1.57-1.26v-5.2c0-.61.42-1.13 1.01-1.26l18.87-4.16c.8-.18 1.57.44 1.57 1.26v5.2c0 .61-.42 1.13-1.01 1.26Z"
        style={{
          fill: "url(#c)",
        }}
      />
      <path
        d="m41.24 33.27-18.87 4.16c-.8.18-1.57-.44-1.57-1.26v-5.2c0-.61.42-1.13 1.01-1.26l18.87-4.16c.8-.18 1.57.44 1.57 1.26v5.2c0 .61-.42 1.13-1.01 1.26Z"
        style={{
          fill: "url(#d)",
        }}
      />
      <path
        d="m41.24 42.78-18.87 4.16c-.8.18-1.57-.44-1.57-1.26v-5.2c0-.61.42-1.13 1.01-1.26l18.87-4.16c.8-.18 1.57.44 1.57 1.26v5.2c0 .61-.42 1.13-1.01 1.26Z"
        style={{
          fill: "url(#e)",
        }}
      />
      <path
        d="m41.24 23.76-18.87 4.16c-.8.18-1.57-.44-1.57-1.26V24.7l21.45-7.39v5.2c0 .61-.42 1.13-1.01 1.26Z"
        style={{
          fill: "url(#f)",
        }}
      />
      <path
        d="m41.24 33.27-18.87 4.16c-.8.18-1.57-.44-1.57-1.26v-1.96l21.45-7.39v5.2c0 .61-.42 1.13-1.01 1.26Z"
        style={{
          fill: "url(#g)",
        }}
      />
      <path
        d="m41.24 42.78-18.87 4.16c-.8.18-1.57-.44-1.57-1.26v-1.96l21.45-7.39v5.2c0 .61-.42 1.13-1.01 1.26Z"
        style={{
          fill: "url(#h)",
        }}
      />
      <path
        d="M31.53 53.2s-.51-8.05 7.94-13.79c7.89-5.36 14.24-4.29 14.24-4.29s-.24 8.92-6.91 14.25c-7.08 5.66-15.27 3.83-15.27 3.83Z"
        style={{
          fill: "#14e37c",
        }}
      />
      <path
        d="M31.53 53.2s2.91-5.02 11.69-8.94c8.71-3.89 10.49-9.14 10.49-9.14s-.24 8.92-6.91 14.25c-7.08 5.66-15.27 3.83-15.27 3.83Z"
        style={{
          fill: "url(#i)",
          opacity: 0.3,
        }}
      />
    </g>
  </svg>
);

export default Scala;
