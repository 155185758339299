import { SVGProps } from "react";

const SoftwaremillLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="82"
    height="28"
    viewBox="0 0 82 28"
    {...props}
  >
    <g clipPath="url(#clip0_1487_115126)">
      <path d="M-0.0033424 26.8663L0.430257 26.3528C0.98902 26.9199 1.75788 27.2325 2.55355 27.2281C3.38499 27.2281 3.93481 26.786 3.93481 26.1741V26.1563C3.93481 25.5802 3.62638 25.2542 2.32558 24.9818C0.899618 24.6737 0.242513 24.2137 0.242513 23.1955V23.1776C0.242513 22.2041 1.10077 21.4896 2.27641 21.4896C3.07209 21.4672 3.84541 21.7352 4.45335 22.2488L4.04657 22.7891C3.55039 22.3559 2.91563 22.1192 2.25853 22.1192C1.45391 22.1192 0.939848 22.5614 0.939848 23.1196V23.1374C0.939848 23.7224 1.25723 24.0484 2.62061 24.3387C4.00187 24.6379 4.63662 25.1426 4.63662 26.0893V26.1027C4.63662 27.1611 3.75154 27.8533 2.52226 27.8533C1.58354 27.8622 0.676113 27.5094 -0.0078125 26.8663H-0.0033424Z" />
      <path d="M6.52735 24.6919V24.674C6.52288 22.9324 7.93097 21.5123 9.67877 21.5078C11.4221 21.5034 12.8436 22.9101 12.8481 24.6562V24.674C12.8525 26.4157 11.4445 27.8358 9.69666 27.8403C7.95332 27.8447 6.53182 26.438 6.52735 24.6919ZM12.1194 24.6919V24.674C12.1731 23.3165 11.1137 22.1732 9.75477 22.1196C9.72795 22.1196 9.70559 22.1196 9.67877 22.1196C8.33327 22.1196 7.24704 23.2137 7.25151 24.5579C7.25151 24.5892 7.25151 24.6249 7.25151 24.6562V24.674C7.19787 26.0316 8.25728 27.1749 9.61619 27.2285C9.64301 27.2285 9.66536 27.2285 9.69218 27.2285C11.0377 27.2285 12.1239 26.1343 12.1194 24.7902C12.1194 24.7589 12.1194 24.7232 12.1194 24.6919Z" />
      <path d="M15.0547 21.5781H19.5069V22.2257H15.752V24.4362H19.1046V25.0748H15.752V27.7721H15.0547V21.5826V21.5781Z" />
      <path d="M23.4966 22.2178H21.418V21.5703H26.2859V22.2178H24.2073V27.7599H23.501V22.2178H23.4966Z" />
      <path d="M27.8443 21.5804H28.6042L30.3744 26.7338L32.073 21.5625H32.6497L34.3483 26.7338L36.1185 21.5804H36.8516L34.6389 27.8146H34.0443L32.3457 22.795L30.6381 27.8146H30.0525L27.8398 21.5804H27.8443Z" />
      <path d="M40.3474 21.5312H41L43.8252 27.7654H43.0742L42.35 26.131H38.9796L38.2465 27.7654H37.5312L40.3519 21.5312H40.3474ZM42.0729 25.5013L40.6648 22.344L39.2478 25.5013H42.0729Z" />
      <path d="M45.5195 21.5779H48.1837C48.8274 21.5422 49.4622 21.7655 49.9449 22.1987C50.2578 22.5247 50.4322 22.9668 50.4232 23.4178V23.4357C50.4232 24.4628 49.717 25.0612 48.7425 25.2398L50.6467 27.7675H49.7885L47.9915 25.3604H46.2213V27.7675H45.524V21.5779H45.5195ZM48.1211 24.7352C49.0509 24.7352 49.7125 24.2574 49.7125 23.4625V23.4446C49.7125 22.6854 49.1269 22.2255 48.1301 22.2255H46.2169V24.7352H48.1211Z" />
      <path d="M53.168 21.5781H57.647V22.2167H53.8698V24.329H57.2492V24.9676H53.8698V27.1335H57.6917V27.7677H53.168V21.5781Z" />
      <path d="M60.3203 21.5781H61.791L63.4181 24.1951L65.0497 21.5781H66.5203V27.7677H65.1659V23.7262L63.4226 26.3699H63.3868L61.6613 23.7529V27.7677H60.3248V21.5781H60.3203Z" />
      <path d="M68.7891 21.5781H70.1524V27.7677H68.7891V21.5781Z" />
      <path d="M78.3945 21.5781H79.7579V26.5306H82.0153V27.7677H78.3945V21.5781Z" />
      <path d="M72.6953 21.5781H74.0587V26.5306H76.3161V27.7677H72.6953V21.5781Z" />
      <path d="M28.9822 0.148438H25.0664V12.6213H28.9822V0.148438Z" />
      <path d="M56.2869 0.148438H52.3711V12.6213H56.2869V0.148438Z" />
      <path d="M39.9197 0.148438H36.0039V12.6213H39.9197V0.148438Z" />
      <path d="M45.3806 2.48828H41.4648V14.9611H45.3806V2.48828Z" />
      <path d="M50.8338 0.148438H46.918V12.6213H50.8338V0.148438Z" />
      <path d="M34.4353 2.10444C34.4353 3.19408 33.5681 4.06044 32.4774 4.06044C31.3867 4.06044 30.5195 3.19408 30.5195 2.10444C30.5195 1.01479 31.3867 0.148438 32.4774 0.148438C33.5681 0.148438 34.4353 1.01479 34.4353 2.10444Z" />
      <path d="M23.5291 10.6591C23.5291 11.7488 22.6619 12.6151 21.5712 12.6151C20.4805 12.6151 19.6133 11.7488 19.6133 10.6591C19.6133 9.56948 20.4805 8.70312 21.5712 8.70312C22.6619 8.70312 23.5291 9.60074 23.5291 10.6591Z" />
      <path d="M61.7439 10.6591C61.7439 11.7488 60.8767 12.6151 59.786 12.6151C58.6953 12.6151 57.8281 11.7488 57.8281 10.6591C57.8281 9.56948 58.6953 8.70312 59.786 8.70312C60.8767 8.70312 61.7439 9.60074 61.7439 10.6591Z" />
    </g>
  </svg>
);

export default SoftwaremillLogo;
