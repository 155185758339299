import React, { SVGProps } from "react";

const Digital = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.46 63.46" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={45.46}
        x2={14.95}
        y1={35.02}
        y2={35.02}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#26a4e1" stopOpacity={0} />
        <stop offset={1} stopColor="#26a4e1" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={56.18}
        x2={36.15}
        y1={24.41}
        y2={44.44}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.28} stopOpacity={0} />
        <stop offset={0.91} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={11.37}
        x2={18.54}
        y1={39.34}
        y2={23.22}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.17} stopColor="#fe9c5a" />
        <stop offset={0.42} stopColor="#fead6d" />
        <stop offset={0.71} stopColor="#fdc88c" />
        <stop offset={1} stopColor="#fdeab3" />
      </linearGradient>
    </defs>
    <rect
      width={30.5}
      height={36.8}
      x={14.95}
      y={13.39}
      rx={2.5}
      ry={2.5}
      style={{
        fill: "#6cd7f3",
      }}
    />
    <path
      d="M30.2 30.27c-.93-2.81-1.62-5.44-3.01-7.5-3.67-5.46-12.24-2.73-12.24 3.85v21.36c0 1.22 1.03 2.2 2.31 2.2h25.89c1.27 0 2.31-.99 2.31-2.2v-7.46c0 4.77-11.07 2.43-15.25-10.25Z"
      style={{
        fill: "url(#a)",
      }}
    />
    <rect
      width={18.73}
      height={26.85}
      x={36.09}
      y={18.73}
      rx={2.5}
      ry={2.5}
      style={{
        fill: "#d4e6e8",
      }}
    />
    <path
      d="M36.09 25.88v17.66c0 1.13.91 2.04 2.04 2.04h14.64c1.13 0 2.04-.91 2.04-2.04v-.16L36.08 25.87Z"
      style={{
        fill: "url(#b)",
        opacity: 0.3,
      }}
    />
    <rect
      width={12.63}
      height={15.44}
      x={8.64}
      y={23.56}
      rx={2.5}
      ry={2.5}
      style={{
        fill: "url(#c)",
      }}
    />
  </svg>
);

export default Digital;
