import { isString } from "lodash";

type PostParams = {
  body: unknown;
  additionalHeaders?: HeadersInit;
  signal?: AbortSignal;
};

type PatchParams = PostParams;

type RequestParams = PostParams & {
  method: "POST" | "PATCH";
};

export type SuccessResult<T> = {
  success: true;
  data?: T;
};

export type ErrorResult<T> = {
  success: false;
  data?: T;
  errorMessage?: string;
};

export type RequestResult<TData = unknown, TError = unknown> =
  | SuccessResult<TData>
  | ErrorResult<TError>;

const makeRequest = async <TData, TError>(
  endpoint: string,
  { body, additionalHeaders, ...rest }: RequestParams
): Promise<RequestResult<TData, TError>> => {
  const res = await fetch(endpoint, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...additionalHeaders,
    },
    body: JSON.stringify(body),
    ...rest,
  });
  const data = await res.json().catch(() => null);
  const rawMessage = data?.error || data?.message;
  const errorMessage = !res.ok && isString(rawMessage) ? rawMessage : undefined;
  return { success: res.ok, data, errorMessage };
};

export const postData = async <TData = unknown, TError = unknown>(
  endpoint: string,
  params: PostParams
): Promise<RequestResult<TData, TError>> =>
  makeRequest(endpoint, { method: "POST", ...params });

export const patchData = async <TData = unknown, TError = unknown>(
  endpoint: string,
  params: PatchParams
): Promise<RequestResult<TData, TError>> =>
  makeRequest(endpoint, { method: "PATCH", ...params });
