import { PictureArrayProps } from "@atoms/image/Image.types";
import { ApiImage } from "@page-components/types";
import { screens } from "@tailwind/screens";
import { ResponsiveImages } from "@utils/images/responsive-images";
import { env } from "@/env";

type MapperElement = {
  media: string;
  mediaMinWidth: number;
  imgWidth: number;
  selectedImageObject: ApiImage | undefined;
};

export const imageBreakpoints = {
  XXL: `(min-width: ${screens.xxl}px)`,
  XL: `(min-width: ${screens.xl}px)`,
  LG: `(min-width: ${screens.lg}px)`,
  MD: `(min-width: ${screens.md}px)`,
  SM: `(min-width: ${screens.sm}px)`,
};

const BREAKPOINTS = [
  imageBreakpoints.XXL,
  imageBreakpoints.XL,
  imageBreakpoints.LG,
  imageBreakpoints.MD,
  imageBreakpoints.SM,
  `(min-width: 300px)`,
];

const baseUrl = env.NEXT_PUBLIC_CDN_URL;
const baseSvgUrl = env.NEXT_PUBLIC_SVG_URL;

const isResponsive = (
  imageObject: ApiImage | ResponsiveImages
): imageObject is ResponsiveImages =>
  "mobile" in imageObject ||
  "tablet" in imageObject ||
  "desktop" in imageObject;

const isSingle = (
  imageObject: ApiImage | ResponsiveImages
): imageObject is ApiImage => "attributes" in imageObject;

const isLocal = (fileName: string | undefined) =>
  fileName && (fileName.startsWith("/_next") || fileName.startsWith("static/"));

const getImageFileName = (url: string | undefined): string => {
  if (!url) return "";
  const pathComponents = url.split("/");

  const fileName = pathComponents[pathComponents.length - 1];

  return fileName;
};

const getFirstNotNilImg = (image: ResponsiveImages) =>
  image.mobile ?? image.tablet ?? image.desktop;

const extractMinWidth = (mediaQuery: string): number | undefined => {
  const match = mediaQuery.match(/\(min-width: (\d+)px\)/);
  if (match && match.length === 2) {
    return parseInt(match[1], 10);
  }
  return undefined;
};

const prepareMapper = (
  breakpoints: string[],
  imageObject: ApiImage | ResponsiveImages
): MapperElement[] =>
  Object.values(breakpoints).map((breakpoint) => {
    let selectedImageObject: ApiImage | undefined;
    if (imageObject && isResponsive(imageObject)) {
      if (
        breakpoint === imageBreakpoints.SM ||
        breakpoint === imageBreakpoints.MD
      ) {
        selectedImageObject = imageObject.tablet;
      } else {
        selectedImageObject = imageObject.desktop;
      }
    } else if (imageObject && isSingle(imageObject)) {
      selectedImageObject = imageObject;
    }
    return {
      media: breakpoint,
      mediaMinWidth: extractMinWidth(breakpoint) as number,
      imgWidth: (extractMinWidth(breakpoint) as number) + 250, // change number after the + to improve quality of img in all breakpoints
      selectedImageObject,
    };
  });

export const generateSrcSet = (
  imageObject: ApiImage | ResponsiveImages,
  maxKnownWidth: number | undefined,
  unoptimized: boolean
): PictureArrayProps => {
  if (unoptimized) return [];
  if (
    (isResponsive(imageObject) &&
      isLocal(getFirstNotNilImg(imageObject)?.attributes?.url)) ||
    (isSingle(imageObject) &&
      (getImageFileName(imageObject.attributes?.url).endsWith(".svg") ||
        isLocal(imageObject.attributes?.url)))
  )
    return [];
  const mapper = prepareMapper(BREAKPOINTS, imageObject); // first, we create a mapper from tailwind breakpoints
  const filteredMapper = mapper.filter((mapObject) => {
    const attrs = mapObject.selectedImageObject?.attributes;
    const imageWidth = attrs?.width || 0;
    const isSvg = getImageFileName(attrs?.url).endsWith(".svg");
    const isAboveMinWidth = imageWidth > mapObject.mediaMinWidth;
    const isMinWidthBelowMaxKnownWidth = maxKnownWidth
      ? mapObject.mediaMinWidth < maxKnownWidth
      : true;

    return isSvg || (isAboveMinWidth && isMinWidthBelowMaxKnownWidth);
  });

  const groupedResponsives = filteredMapper.map((filteredMapObject, index) => {
    const fileName = getImageFileName(
      filteredMapObject.selectedImageObject?.attributes?.url
    );
    const width =
      index === 0 && maxKnownWidth
        ? maxKnownWidth
        : filteredMapObject.media === `(min-width: ${screens.xxl}px)` &&
            !maxKnownWidth
          ? 1920
          : filteredMapObject.imgWidth;
    let url = "";
    if (fileName.endsWith(".svg")) {
      url = `${baseSvgUrl}${fileName}`;
    } else {
      url = `${baseUrl}${fileName}?format=webp&width=${width} 1x, ${baseUrl}${fileName}?format=webp&width=${
        width * 2
      } 2x`;
    }
    return {
      url,
      condition: filteredMapObject.media,
    };
  }); //finally prepare urls and conditions

  if (groupedResponsives) {
    return groupedResponsives;
  }
  return [];
};

export const generateBackupFileNameForResponsiveImages = (
  imageData: ResponsiveImages
) => {
  if (imageData.mobile)
    return {
      name: getImageFileName(imageData?.mobile?.attributes?.url),
      path: imageData?.mobile?.attributes?.url,
    };
  if (imageData.tablet)
    return {
      name: getImageFileName(imageData?.tablet?.attributes?.url),
      path: imageData?.tablet?.attributes?.url,
    };
  if (imageData.desktop)
    return {
      name: getImageFileName(imageData?.desktop?.attributes?.url),
      path: imageData?.desktop?.attributes?.url,
    };
  return { name: "", path: "" };
};

export const setBackupImageUrl = (
  imageData: ApiImage | string | ResponsiveImages | undefined,
  unoptimized: boolean,
  maxKnownWidth: number | undefined
) => {
  let defaultWidthParam = "";
  if (typeof imageData === "string") {
    let params = "format=webp";
    if (maxKnownWidth && !unoptimized) params += `&width=${maxKnownWidth}`;
    return isLocal(imageData)
      ? imageData
      : `${baseUrl}${getImageFileName(imageData)}?${params}`;
  }
  if (imageData && isResponsive(imageData)) {
    const { name, path } = generateBackupFileNameForResponsiveImages(imageData);
    if (isLocal(path)) return path;
    if (name.endsWith(".svg")) return `${baseSvgUrl}${name}`;
    if ((imageData.mobile?.attributes?.width ?? 0) > 500 && !unoptimized) {
      defaultWidthParam = "&width=500";
    }
    if (maxKnownWidth && !unoptimized) {
      defaultWidthParam = `&width=${maxKnownWidth}`;
    }
    return `${baseUrl}${name}?format=webp${defaultWidthParam}`;
  } else if (imageData && isSingle(imageData)) {
    const fileName = getImageFileName(imageData?.attributes?.url);
    if (isLocal(imageData?.attributes?.url)) return imageData?.attributes?.url;
    if (fileName.endsWith(".svg")) return `${baseSvgUrl}${fileName}`;
    if ((imageData?.attributes?.width ?? 0) > 500 && !unoptimized) {
      defaultWidthParam = "&width=500";
    }
    if (maxKnownWidth && !unoptimized) {
      defaultWidthParam = `&width=${maxKnownWidth}`;
    }
    return `${baseUrl}${getImageFileName(
      imageData?.attributes?.url
    )}?format=webp${defaultWidthParam}`;
  }
};

export const setAltImage = (
  imageData: ApiImage | string | ResponsiveImages | undefined
) => {
  if (typeof imageData === "string") return "Image Alt";
  if (imageData && isSingle(imageData))
    return imageData.attributes?.alternativeText || "Image Alt";
  if (imageData && isResponsive(imageData))
    return imageData.mobile?.attributes?.alternativeText || "Image Alt";
  return "Image Alt";
};

export const setWidthAndHeight = (
  imageData: ApiImage | string | ResponsiveImages | undefined
) => {
  if (typeof imageData === "string") return [0, 0];
  if (imageData && isSingle(imageData))
    return [
      imageData.attributes?.width || 0,
      imageData.attributes?.height || 0,
    ];
  if (imageData && isResponsive(imageData))
    return [
      imageData.desktop?.attributes?.width || 0,
      imageData.desktop?.attributes?.height || 0,
    ];
  return [0, 0];
};
