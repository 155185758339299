import React, { SVGProps } from "react";

const Plus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    {...props}
  >
    <path d="M13.8002 6.3281V7.7041H7.7202V13.6241H6.2802V7.7041H0.200195V6.3281H6.2802V0.376099H7.7202V6.3281H13.8002Z" />
  </svg>
);

export default Plus;
