import { SVGProps } from "react";

const ArrowRightShort = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="11"
    viewBox="0 0 13 11"
    {...props}
  >
    <path d="M0.669682 6.53359L8.40676 6.53358L5.78592 9.15442L7.24771 10.6162L12.364 5.49995L7.24771 0.3837L5.78593 1.84549L8.40676 4.46632L0.669668 4.46632L0.669682 6.53359Z" />
  </svg>
);

export default ArrowRightShort;
