import { SVGProps } from "react";

const TimeOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 25 25"
    fill="none"
    stroke={props.color || props.fill}
    className={props.className}
  >
    <path
      d="M12.5 3.125C7.32422 3.125 3.125 7.32422 3.125 12.5C3.125 17.6758 7.32422 21.875 12.5 21.875C17.6758 21.875 21.875 17.6758 21.875 12.5C21.875 7.32422 17.6758 3.125 12.5 3.125Z"
      strokeWidth="1.5625"
      strokeMiterlimit="10"
    />
    <path
      d="M12.5 6.25V13.2812H17.1875"
      strokeWidth="1.5625"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default TimeOutline;
