import { allIcons, IconsNames } from "components/lib/icons/allIcons";
import { SVGProps } from "react";
import { AnimatedIcon } from "../animated-icon/AnimatedIcon";
import { Image } from "../image/Image";

export type DynamicIconComponentProps = {
  isAnimated?: boolean;
  url?: string;
  width?: number;
  height?: number;
  backgroundImage?: boolean;
  maxKnownWidth?: number;
  className?: string;
};

export interface IconProps extends SVGProps<SVGSVGElement> {
  name: IconsNames;
}

const Icon = ({ name, fill = "currentColor", ...rest }: IconProps) => {
  const Component = allIcons[name];
  return Component ? <Component fill={fill} {...rest} /> : null;
};

export const DynamicIconComponent = ({
  isAnimated,
  width,
  height,
  url,
  backgroundImage,
  maxKnownWidth,
  className,
}: DynamicIconComponentProps) => {
  if (!url) return <></>;

  if (isAnimated)
    return (
      <AnimatedIcon className={className} fill={backgroundImage} url={url} />
    );

  return (
    <Image
      backgroundImage={backgroundImage}
      className={className}
      imageData={{ attributes: { width, height, url } }}
      maxKnownWidth={maxKnownWidth}
    />
  );
};

export default Icon;
