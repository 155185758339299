import { FC, HTMLAttributes, ReactNode } from "react";
import styles from "./Skipper.module.scss";

interface SkipperProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode | undefined;
}

export const Skipper: FC<SkipperProps> = ({ children }) => (
  <>
    <a className={styles.skipLink} href="#maincontent">
      Skip to main content
    </a>
    {children}
    <div id="maincontent" />
  </>
);
