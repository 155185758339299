import { SVGProps } from "react";

const BulbLightOn = ({
  className,
  width,
  height,
  stroke,
  fill,
  color,
}: SVGProps<SVGSVGElement> & {
  stroke?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34 32"
    fill="none"
    width={width}
    height={height}
    className={className}
    stroke={stroke || fill || color}
  >
    <path
      d="M19.9358 24.4443V27.2739C19.9358 28.8715 18.6387 30.1667 17.0387 30.1667C15.4539 30.1667 14.1632 28.8952 14.1418 27.313L14.1029 24.4443M19.9358 24.4443H14.1029M19.9358 24.4443C19.9358 23.4587 20.5633 22.6026 21.3912 22.0661C23.504 20.6969 24.8947 18.3676 24.8947 15.7244C24.8947 11.5106 21.3601 8.09459 17 8.09459C12.6399 8.09459 9.10526 11.5106 9.10526 15.7244C9.10526 18.3675 10.4958 20.6966 12.6084 22.0658C13.4402 22.6049 14.0795 23.4544 14.1029 24.4443M17 4.15315V1M28.8421 15.9775H32M25.3733 24.3386L27.6062 26.5682M2 15.9775H5.15789M6.39352 5.38678L8.62649 7.6164M8.62596 24.3382L6.39299 26.5678M27.6065 5.38693L25.3735 7.61654"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BulbLightOn;
