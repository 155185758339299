import { FC } from "react";
import { cn } from "utils/tailwind";
import { colorClasses, sizeClasses, widthClasses } from "./Loader.constants";
import { LoaderProps } from "./Loader.types";

export const Loader: FC<LoaderProps> = ({
  className,
  size = 4,
  color,
  width = 2,
}) => (
  <span
    className={cn(
      "box-border inline-block animate-spin rounded-full border-2 border-current border-b-transparent",
      sizeClasses[size],
      color && colorClasses[color],
      widthClasses[width],
      className
    )}
  />
);
