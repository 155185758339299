import React, { SVGProps } from "react";

const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="10"
    viewBox="0 0 16 10"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1141 5.50494L0 5.50494L0 4.49427L14.1141 4.49427L11.0142 1.31426L11.7109 0.599609L16 4.99961L11.7109 9.39961L11.0142 8.68496L14.1141 5.50494Z"
    />
  </svg>
);

export default ArrowRight;
