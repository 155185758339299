"use client";

import { cn } from "@utils/tailwind";
import defaultIcon from "public/icons/default-animated-icon.webm";
import { FC, useEffect, useState } from "react";
import ReactPlayer from "react-player";

type AnimatedIconProps = { url?: string; fill?: boolean; className?: string };

export const AnimatedIcon: FC<AnimatedIconProps> = ({
  url,
  fill,
  className,
}) => {
  const [hasWindow, setHasWindow] = useState(false);
  const iconUrl = url ?? defaultIcon;

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);
  const fillClasses = "absolute object-cover top-0 left-0 w-full h-full";
  return (
    hasWindow && (
      <ReactPlayer
        url={iconUrl}
        playing
        playsinline
        loop
        stopOnUnmount={false}
        muted
        width="100%"
        height="100%"
        disableRemotePlayback
        className={cn(fill && fillClasses, className)}
      />
    )
  );
};
