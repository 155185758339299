import React, { SVGProps } from "react";

const Success = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={12}
    viewBox="0 0 14 12"
    fill="#0B0A0A"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M5.513 7.131 11.897.5 14 2.684 5.513 11.5 0 5.774l2.103-2.185 3.41 3.542Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Success;
