import React, { SVGProps } from "react";

const LinkedIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="#E3F2FA"
    {...props}
  >
    <path d="M19.006 0H.87a.845.845 0 0 0-.81.537.86.86 0 0 0-.06.338V19.25c0 .375.373.75.87.75h18.26a.845.845 0 0 0 .81-.537.86.86 0 0 0 .06-.338V.875c-.124-.5-.497-.875-.994-.875ZM5.838 17H2.982V7.5h2.98v9.498H5.84V17ZM4.472 6.248c-.994 0-1.739-.875-1.739-1.75 0-1 .745-1.75 1.739-1.75.994 0 1.739.75 1.739 1.75-.124.875-.87 1.75-1.74 1.75Zm12.423 10.75h-2.982v-4.625c0-1.124 0-2.499-1.491-2.499-1.49 0-1.739 1.25-1.739 2.5v4.75H7.701V7.5h2.858v1.25c.372-.75 1.366-1.5 2.732-1.5 2.982 0 3.478 2 3.478 4.5V17h.126v-.002Z" />
  </svg>
);
export default LinkedIn;
