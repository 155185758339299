import { Button } from "@atoms/button/Button";
import { FC } from "react";

interface ContactButtonProps {
  darkTheme: boolean;
  buttonText: string;
  forceGreenColor?: boolean;
}

export const ContactButton: FC<ContactButtonProps> = ({
  darkTheme,
  buttonText,
  forceGreenColor,
}) => (
  <div className="relative block px-7 pb-9 md:px-14 lg:hidden lg:px-0 lg:pb-0 lg:pt-6 xl:block [@media(max-width:1023px)]:bg-white">
    <Button
      intent={forceGreenColor ? "primaryV2" : "secondaryV2"}
      background={darkTheme ? "dark" : "light"}
      icon="ArrowRight"
      size="small"
      className="block"
      asLink
      href="/contact"
      id="menu-cta"
    >
      {buttonText}
    </Button>
  </div>
);
