import { SVGProps } from "react";

const ArrowRightThin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.00576 5.57424L0 5.57424L0 4.42576L9.00576 4.42576L5.72777 0.812103L6.46443 0L11 5L6.46443 10L5.72777 9.1879L9.00576 5.57424Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowRightThin;
