import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export const tw = (
  strings: readonly string[] | ArrayLike<string>,
  ...values: never[]
) =>
  String.raw({ raw: strings }, ...values)
    .replace(/\n/g, "")
    .replace(/\s+/g, " ");
