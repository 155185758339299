import React, { SVGProps } from "react";

const Legacy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.46 63.46" {...props}>
    <defs>
      <linearGradient
        id="c"
        x1={39.83}
        x2={55.76}
        y1={46.49}
        y2={42.22}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.17} stopColor="#fe9c5a" />
        <stop offset={0.42} stopColor="#fead6d" />
        <stop offset={0.71} stopColor="#fdc88c" />
        <stop offset={1} stopColor="#fdeab3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={58.02}
        x2={39.55}
        y1={45.59}
        y2={45.59}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.75} stopColor="#ff9754" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="e"
        x1={28.85}
        x2={34.04}
        y1={24.85}
        y2={45.68}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={0.91} />
      </linearGradient>
      <linearGradient
        id="f"
        x1={21.65}
        x2={14.02}
        y1={36.78}
        y2={49.48}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={0.03} stopColor="#272723" stopOpacity={0.1} />
        <stop offset={0.91} />
      </linearGradient>
      <style>
        {
          ".i{fill:none;stroke:#009c3f;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2.3px}"
        }
      </style>
    </defs>
    <g id="b">
      <rect
        width={16.49}
        height={16.49}
        x={39.55}
        y={36.11}
        rx={8.24}
        ry={8.24}
        style={{
          fill: "url(#c)",
        }}
      />
      <path
        d="M53.15 48.17c-5.77 0-10.18-4.23-11.25-9.58a8.208 8.208 0 0 0-2.36 5.77c0 4.55 3.69 8.24 8.24 8.24 3.26 0 6.07-1.9 7.41-4.64-.67.13-1.36.21-2.05.21Z"
        style={{
          fill: "url(#d)",
        }}
      />
      <rect
        width={26.1}
        height={26.1}
        x={17.72}
        y={15.92}
        rx={2.5}
        ry={2.5}
        style={{
          fill: "#14e37c",
        }}
        transform="rotate(-45 30.768 28.966)"
      />
      <path
        d="M47.79 30.4 32.48 45.71c-.94.94-2.48.94-3.42 0L12.32 28.97a63.878 63.878 0 0 1 35.1 1.31l.37.12Z"
        style={{
          fill: "url(#e)",
          opacity: 0.3,
        }}
      />
      <path d="m35.11 31.22 11.04 11.05M21.21 42.14l-4.52-4.52" />
      <rect
        width={18.81}
        height={18.81}
        x={10.4}
        y={30.75}
        rx={2.5}
        ry={2.5}
        style={{
          fill: "#d4e6e8",
        }}
        transform="rotate(-45 19.807 40.166)"
      />
      <path
        d="m30.92 42.35-9.67 9.67c-.8.8-2.09.8-2.88 0L7.98 41.63c-.81-.81-.81-2.13 0-2.95l.71-.71a6.41 6.41 0 0 1 6.81-1.46l15.42 5.84Z"
        style={{
          opacity: 0.3,
          fill: "url(#f)",
        }}
      />
    </g>
  </svg>
);

export default Legacy;
