import { tw } from "@utils/tailwind";
import { ButtonCompoundVariant, ButtonVariantStyles } from "../Button.types";

export const tertiaryV2ButtonVariants: ButtonVariantStyles = {
  base: tw`
    rounded-full
    border-transparent
    bg-transparent
    font-termina
    font-medium
    focus:border-transparent
    focus:text-green-accent-two
  `,
  light: ["text-content-primary-on-light"],
  dark: ["text-content-primary-on-dark"],
};

const { light, dark } = tertiaryV2ButtonVariants;

export const tertiaryV2CompoundVariants: ButtonCompoundVariant[] = [
  {
    intent: "tertiaryV2",
    background: "light",
    className: light,
  },
  {
    intent: "tertiaryV2",
    background: "dark",
    className: dark,
  },
  {
    intent: "tertiaryV2",
    size: "big",
    className: "xl:px-12 xl:py-4 px-9 py-3 xl:text-base text-xs",
  },
  {
    intent: "tertiaryV2",
    size: "medium",
    className: "xl:px-12 xl:py-4 px-9 py-3 xl:text-base text-xs",
  },
  {
    intent: "tertiaryV2",
    size: "small",
    className: "px-9 py-3 text-xs",
  },
];
