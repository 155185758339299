import React, { SVGProps } from "react";

const Solutions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 63.46 63.46"
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        x1={35.86}
        x2={15.25}
        y1={19.93}
        y2={45.39}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1} stopColor="#272723" stopOpacity={0} />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="a"
        x1={34.4}
        x2={35.62}
        y1={29.8}
        y2={43.76}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#009de2" />
        <stop offset={1} stopColor="#0057a3" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={51.38}
        x2={52.6}
        y1={29.37}
        y2={43.33}
      />
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={42.89}
        x2={44.16}
        y1={28.77}
        y2={43.28}
      />
      <linearGradient
        id="e"
        x1={27.09}
        x2={9.44}
        y1={24.01}
        y2={41.66}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.28} stopOpacity={0} />
        <stop offset={0.91} />
      </linearGradient>
    </defs>
    <rect
      width={26.87}
      height={32.42}
      x={17.11}
      y={15.63}
      rx={2.5}
      ry={2.5}
      style={{
        fill: "#14e37c",
      }}
    />
    <g
      style={{
        opacity: 0.3,
      }}
    >
      <path
        d="M34.18 48.04H19.31a2.2 2.2 0 0 1-2.2-2.2V17.83c0-1.22.99-2.2 2.2-2.2h11.23l3.64 32.42Z"
        style={{
          fill: "url(#b)",
        }}
      />
    </g>
    <path
      d="m33.46 36.43 4.79-5.07c.41-.44.39-1.12-.04-1.53-.44-.41-1.12-.39-1.53.04l-5.47 5.8-.09.15c-.04.06-.08.11-.11.18-.03.07-.04.13-.05.2-.01.07-.03.13-.03.2s.02.14.03.2c.01.07.02.14.05.2.03.07.07.12.11.19.03.05.05.1.09.15l5.47 6.1c.21.24.51.36.81.36.26 0 .52-.09.72-.28.45-.4.48-1.09.08-1.53l-4.81-5.35Z"
      style={{
        fill: "url(#a)",
      }}
    />
    <path
      d="M56.06 36.93c.01-.07.03-.13.03-.2s-.02-.14-.03-.2a.653.653 0 0 0-.05-.2c-.03-.07-.07-.12-.11-.19-.03-.05-.05-.1-.09-.15l-5.47-6.1c-.4-.45-1.08-.48-1.53-.08-.45.4-.48 1.09-.08 1.53l4.81 5.35-4.79 5.07a1.087 1.087 0 0 0 .78 1.83c.29 0 .58-.11.79-.34l5.47-5.8.09-.15c.04-.06.08-.11.11-.18.03-.07.04-.13.05-.2Z"
      style={{
        fill: "url(#c)",
      }}
    />
    <path
      d="M40.51 43.6c-.15 0-.31-.03-.46-.1-.54-.25-.78-.9-.53-1.44l6.03-12.98c.25-.54.9-.78 1.44-.53.54.25.78.9.53 1.44l-6.03 12.98c-.18.39-.58.63-.98.63Z"
      style={{
        fill: "url(#d)",
      }}
    />
    <rect
      width={21.3}
      height={25.69}
      x={7.36}
      y={19.43}
      rx={2.5}
      ry={2.5}
      style={{
        fill: "#d4e6e8",
      }}
    />
    <path
      d="M9.39 25.3v15.56c0 .99.81 1.8 1.8 1.8h12.9c.99 0 1.8-.81 1.8-1.8v-.14L9.39 25.3Z"
      style={{
        fill: "url(#e)",
        opacity: 0.3,
      }}
    />
  </svg>
);

export default Solutions;
