import { FC, HTMLAttributes, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface BackdropProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  /** mobileOpen from parent */
  state: boolean;
  children?: ReactNode | undefined;
}

export const Backdrop: FC<BackdropProps> = ({
  className,
  state,
  children,
  ...props
}) => (
  <div
    className={twMerge(
      "lg:flex lg:flex-grow lg:justify-between",
      "h-[calc(100%-78px)] lg:h-auto",
      "[@media(max-width:1023px)]:overflow-y-auto [@media(max-width:1023px)]:backdrop-blur-sm [@media(max-width:1023px)]:backdrop-brightness-90",
      state ? "block" : "hidden",
      className
    )}
    {...props}
  >
    {children}
  </div>
);
