/* eslint-disable no-process-env */
import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

const string = z.string().min(1);
const optBoolean = z
  .string()
  .optional()
  .transform((s) => s === "true" || s === "1");
const url = z.string().url();

export const env = createEnv({
  server: {
    DISABLE_ANALYTIC_SCRIPTS: optBoolean,
    DISABLE_ENV_VALIDATION: optBoolean,
    HUBSPOT_API_KEY: string,
    HUBSPOT_PORTAL_ID: string,
    HUBSPOT_FORM_ID: string,
    CLIENT_PREVIEW_SECRET: string,
    NODE_ENV: z.enum(["production", "development", "test"]),
  },
  client: {
    NEXT_PUBLIC_DOMAIN: url,
    NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY: string,
    NEXT_PUBLIC_GOOGLE_RECAPTCHA_SERVER_KEY: string,
    NEXT_PUBLIC_MEILISEARCH_HOST: url,
    NEXT_PUBLIC_MEILISEARCH_API_KEY: string,
    NEXT_PUBLIC_API_GLOBAL_ENDPOINT: url,
    NEXT_PUBLIC_CDN_URL: url,
    NEXT_PUBLIC_SVG_URL: url,
  },
  // Destructure only client variables here:
  experimental__runtimeEnv: {
    NEXT_PUBLIC_DOMAIN: process.env.NEXT_PUBLIC_DOMAIN,
    NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY:
      process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY,
    NEXT_PUBLIC_GOOGLE_RECAPTCHA_SERVER_KEY:
      process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SERVER_KEY,
    NEXT_PUBLIC_MEILISEARCH_HOST: process.env.NEXT_PUBLIC_MEILISEARCH_HOST,
    NEXT_PUBLIC_MEILISEARCH_API_KEY:
      process.env.NEXT_PUBLIC_MEILISEARCH_API_KEY,
    NEXT_PUBLIC_API_GLOBAL_ENDPOINT:
      process.env.NEXT_PUBLIC_API_GLOBAL_ENDPOINT,
    NEXT_PUBLIC_CDN_URL: process.env.NEXT_PUBLIC_CDN_URL,
    NEXT_PUBLIC_SVG_URL: process.env.NEXT_PUBLIC_SVG_URL,
  },
});
