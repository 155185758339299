import React, { SVGProps } from "react";

const Strategy = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.46 63.46" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={18.66}
        x2={17.94}
        y1={34.92}
        y2={55.33}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.28} stopOpacity={0} />
        <stop offset={0.91} />
      </linearGradient>
      <linearGradient
        id="b"
        x1={33.55}
        x2={55.08}
        y1={46.9}
        y2={41.13}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.17} stopColor="#fe9c5a" />
        <stop offset={0.42} stopColor="#fead6d" />
        <stop offset={0.71} stopColor="#fdc88c" />
        <stop offset={1} stopColor="#fdeab3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={58.02}
        x2={33.27}
        y1={45.67}
        y2={45.67}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.75} stopColor="#ff9754" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={29.38}
        x2={23.2}
        y1={12.59}
        y2={31.61}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#26a4e1" stopOpacity={0} />
        <stop offset={1} stopColor="#26a4e1" />
      </linearGradient>
    </defs>
    <path
      d="m36.65 25.19 6.47 12.51"
      style={{
        fill: "none",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: "2.3px",
        stroke: "#6cd7f3",
      }}
    />
    <rect
      width={20.43}
      height={20.43}
      x={8.09}
      y={34.63}
      rx={2.5}
      ry={2.5}
      style={{
        fill: "#14e37c",
      }}
    />
    <path
      d="M26.46 55.06h-16.3c-1.14 0-2.06-.92-2.06-2.06V36.69c0-1.14.92-2.06 2.06-2.06l1.34 6.01c1.13 5.06 4.96 9.08 9.97 10.44L28.54 53c0 1.14-.92 2.06-2.06 2.06Z"
      style={{
        fill: "url(#a)",
        opacity: 0.3,
      }}
    />
    <rect
      width={22.09}
      height={22.09}
      x={33.27}
      y={32.96}
      rx={10.61}
      ry={10.61}
      style={{
        fill: "url(#b)",
      }}
    />
    <path
      d="M51.5 49.12c-7.73 0-13.65-5.66-15.07-12.83a11.017 11.017 0 0 0-3.16 7.73c0 6.1 4.95 11.05 11.05 11.05 4.37 0 8.13-2.54 9.93-6.22-.9.18-1.82.28-2.74.28Z"
      style={{
        fill: "url(#c)",
      }}
    />
    <path
      d="m25.08 9.35-7.41 7.41a2.5 2.5 0 0 0-.65 2.41l2.71 10.12c.23.86.91 1.54 1.77 1.77l10.12 2.71a2.5 2.5 0 0 0 2.41-.65l7.41-7.41a2.5 2.5 0 0 0 .65-2.41l-2.71-10.12c-.23-.86-.91-1.54-1.77-1.77L27.49 8.7a2.5 2.5 0 0 0-2.41.65Z"
      style={{
        fill: "#6cd7f3",
      }}
    />
    <path
      d="m31 13.94-13.19 3.53c-.64.17-1.02.83-.85 1.47l2.77 10.35c.23.86.91 1.54 1.77 1.77l10.09 2.7c.78.21 1.59-.26 1.8-1.04l3.46-12.93c.95-3.56-2.3-6.82-5.86-5.86Z"
      style={{
        fill: "url(#d)",
      }}
    />
    <path
      d="m27.33 25.2-9.51 16.64c-.77 1.34.2 3.01 1.75 3.01h13.02"
      style={{
        stroke: "#009c3f",
        fill: "none",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: "2.3px",
      }}
    />
  </svg>
);

export default Strategy;
