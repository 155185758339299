import { ButtonCompoundVariant, ButtonVariantStyles } from "../Button.types";

export const primaryButtonVariants: ButtonVariantStyles = {
  base: [
    "font-termina",
    "font-medium",
    "rounded-[50px]",
    "active:text-content-primary-on-dark",
    "active:bg-green-dark",
    "focus:text-green-accent-one",
    "focus:bg-grey-scale-off-black",
  ],
  light: [
    "text-grey-scale-off-black",
    "border-grey-scale-off-black",
    "hover:text-content-primary-on-dark",
    "hover:bg-grey-scale-off-black",
    "hover:border-grey-scale-off-black",
  ],
  dark: [
    "text-content-primary-on-dark",
    "border-content-primary-on-dark",
    "hover:text-grey-scale-off-black",
    "hover:bg-content-primary-on-dark",
    "hover:border-content-primary-on-dark",
  ],
};

const { light, dark } = primaryButtonVariants;

export const primaryCompoundVariants: ButtonCompoundVariant[] = [
  {
    intent: "primary",
    background: "light",
    className: light,
  },
  {
    intent: "primary",
    background: "dark",
    className: dark,
  },
  {
    intent: "primary",
    size: "big",
    className: "gap-3 px-12 py-6 text-2xl",
  },
  {
    intent: "primary",
    size: "medium",
    className: "gap-3 px-8 py-3 text-xs",
  },
  {
    intent: "primary",
    size: "small",
    className: "gap-2 px-5 py-2 text-xs",
  },
];
