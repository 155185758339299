import { FontSize, FontWeight } from "./Typography.types";

export const fontVariantMap: Record<FontSize, string> = {
  "display-h1": "text-4xl md:text-5xl xl:text-6xl",
  h1: "text-3xl md:text-4xl xl:text-5xl",
  h2: "text-2xl md:text-3xl xl:text-4xl",
  h3: "text-xl md:text-2xl xl:text-3xl",
  h4: "text-lg md:text-xl xl:text-2xl",
  h5: "text-base md:text-lg xl:text-xl",
  h6: "text-xs md:text-base xl:text-lg",
  xl: "text-lg md:text-xl xl:text-2xl",
  lg: "text-base md:text-lg xl:text-xl",
  base: "text-sm md:text-base xl:text-lg",
  sm: "text-xs md:text-sm xl:text-base",
  xs: "text-xs xl:text-sm",
};

export const fontWeightMap: Record<FontWeight, string> = {
  normal: "font-normal",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold",
};
