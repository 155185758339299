import { SVGProps } from "react";

const VirtusLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="73"
    height="60"
    viewBox="0 0 73 60"
    {...props}
  >
    <path d="M72.9188 11.2705C72.7488 10.8555 72.3661 10.5934 71.9197 10.5934H67.5407C67.3068 10.5934 67.0943 10.6807 66.9029 10.8118L60.3131 15.8791L60.2068 15.6607L52.4478 0.76447C52.4478 0.76447 52.4265 0.720786 52.4053 0.698944V0.65526H52.384L52.3202 0.589734C52.3202 0.589734 52.2352 0.502366 52.1927 0.458682C52.1502 0.414998 52.1289 0.393156 52.1077 0.393156C52.0439 0.349472 52.0014 0.32763 51.9376 0.305788C51.8738 0.283946 51.8526 0.262104 51.8313 0.262104C51.7463 0.240262 51.6613 0.21842 51.5762 0.21842L51.5124 0V0.21842C51.5124 0.21842 51.3211 0.21842 51.2361 0.240262H51.2148L34.1663 4.93629C33.8474 5.02366 33.571 5.28577 33.4435 5.59155L16.5437 50.4332L11.123 36.7819C10.9742 36.4106 10.6554 36.1485 10.2727 36.1048L1.217 34.8817C1.217 34.8817 1.13197 34.8817 1.06819 34.8817C0.706815 34.8817 0.366693 35.0564 0.175375 35.3841C-0.0584584 35.7335 -0.0584584 36.1922 0.175375 36.5635L13.6314 59.4976C13.6314 59.4976 13.6739 59.5413 13.6952 59.5632L13.7377 59.6287C13.7377 59.6287 13.7802 59.6724 13.7802 59.6942C13.8227 59.7161 13.8653 59.7597 13.9078 59.7816L13.9928 59.8471C13.9928 59.8471 14.0778 59.8908 14.1416 59.9126L14.2266 59.9563C14.2266 59.9563 14.3967 60 14.4817 60H18.712C19.0946 60 19.456 59.7816 19.6261 59.4539L44.9864 14.8963L54.8712 21.9949C54.8712 21.9949 54.9562 22.0386 55.02 22.0604C55.0625 22.0604 55.105 22.0823 55.1688 22.1041C55.2325 22.1041 55.2538 22.126 55.275 22.126L62.0137 22.8249C62.3326 22.8249 62.6514 22.7375 62.8853 22.4973L72.685 12.4499C72.9826 12.1223 73.0889 11.6636 72.9188 11.2486V11.2705ZM14.3329 56.3305L3.19395 37.328L9.42243 38.158L9.46494 38.2672L15.4596 53.3819L14.3542 56.3305H14.3329ZM18.138 57.7284H16.0973L17.6066 53.7313L35.2717 6.88023H35.3779L49.2804 3.03604L49.0253 3.49472L18.1806 57.7284H18.138ZM55.2325 19.5923L46.0918 12.996L51.4699 3.53841L51.64 3.88788L58.57 17.2333L55.3601 19.7015L55.2325 19.6141V19.5923ZM61.7374 20.597L58.2299 20.2257L67.9021 12.7776H69.3476L61.7374 20.5752V20.597Z" />
  </svg>
);

export default VirtusLogo;
