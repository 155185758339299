import { SVGProps } from "react";

const Hexagon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="40"
    viewBox="0 0 36 40"
    xmlns="http://www.w3.org/2000/svg"
    fill="#67E387"
    {...props}
  >
    <path
      id="Vector"
      d="M16.578 39.7112L1.35 31.0268C0.513 30.5512 0 29.6712 0 28.7157V11.3512C0 10.3957 0.513 9.51569 1.35 9.04013L16.578 0.35569C17.415 -0.119865 18.441 -0.119865 19.278 0.35569L34.506 9.04013C35.343 9.51569 35.856 10.3957 35.856 11.3512V28.7157C35.856 29.6668 35.343 30.5512 34.506 31.0268L19.278 39.7112C18.441 40.1868 17.415 40.1868 16.578 39.7112ZM2.682 30.2712L16.578 38.1957C17.415 38.6712 18.441 38.6712 19.278 38.1957L33.174 30.2712C34.011 29.7957 34.524 28.9157 34.524 27.9601V12.1112C34.524 11.1601 34.011 10.2757 33.174 9.80013L19.278 1.87569C18.441 1.40013 17.415 1.40013 16.578 1.87569L2.682 9.80013C1.845 10.2757 1.332 11.1557 1.332 12.1112V27.9601C1.332 28.9112 1.845 29.7957 2.682 30.2712Z"
    />
  </svg>
);

export default Hexagon;
