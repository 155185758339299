import { SVGProps } from "react";

const CircledPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="currentColor"
    {...props}
  >
    <path d="M8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5C16 12.9183 12.4183 16.5 8 16.5ZM8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5ZM12 7.5H9V4.5H7V7.5H4V9.5H7V12.5H9V9.5H12V7.5Z" />
  </svg>
);

export default CircledPlus;
