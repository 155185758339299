import { SVGProps } from "react";

const LinkedInGray = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#596973"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_376_8333)">
      <path d="M11.9971 23.9942C18.6229 23.9942 23.9942 18.6229 23.9942 11.9971C23.9942 5.37129 18.6229 0 11.9971 0C5.37129 0 0 5.37129 0 11.9971C0 18.6229 5.37129 23.9942 11.9971 23.9942Z" />
      <path
        d="M9.19012 15.922H7.17427V9.97406H9.28039V15.922H9.19442H9.19012ZM8.22733 9.19004C7.52672 9.19004 6.99805 8.64409 6.99805 8.09404C6.99805 7.466 7.52243 6.99805 8.22733 6.99805C8.93223 6.99805 9.45661 7.466 9.45661 8.09404C9.37065 8.63999 8.84197 9.19004 8.22733 9.19004ZM16.9956 15.9179H14.8895V13.024C14.8895 12.322 14.8895 11.46 13.8365 11.46C12.7834 11.46 12.6072 12.244 12.6072 13.024V15.9959H10.5011V9.96996H12.5169V10.754C12.7791 10.286 13.4797 9.81397 14.4468 9.81397C16.5529 9.81397 16.9011 11.066 16.9011 12.6299V15.9138H16.9913L16.9956 15.9179Z"
        fill="#F9F9F9"
      />
    </g>
    <defs>
      <clipPath id="clip0_376_8333">
        <rect width="23.9942" height="23.9942" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default LinkedInGray;
