import Icon from "@atoms/icon/Icon";
import { NavLink } from "@layout/navigation/Nav.types";
import { isExternal } from "@utils/helpers/isExternal";
import Link from "next/link";
import { FC } from "react";
import { twMerge } from "tailwind-merge";
import styles from "./GreenLink.module.scss";

interface GreenLinkProps {
  className?: string;
  link: NavLink & { description?: string };
}

export const GreenLink: FC<GreenLinkProps> = ({ className, link }) => (
  <Link
    role="menuitem"
    href={link.href}
    target={link.target}
    className={twMerge(
      "text-lg font-medium lg:text-sm",
      "text-green-saturated",
      "focus:outline-disabled focus:text-black",
      "block",
      "py-3 lg:py-0",
      styles.greenLink,
      link.description ? "" : "py-3 lg:inline-block lg:py-2",
      className
    )}
  >
    <div className="relative inline-block text-nowrap uppercase lg:text-sm">
      <span data-icon={isExternal(link.href) ? "external" : "internal"}>
        <Icon
          width={12}
          height={12}
          name={isExternal(link.href) ? "ArrowSkewThin" : "ArrowRightThin"}
        />
      </span>
      <span>{link.name}</span>
    </div>
    {link.description && (
      <div className="hidden pt-4 text-xs text-grey-scale-gray lg:block">
        {link.description}
      </div>
    )}
  </Link>
);
