import { ButtonCompoundVariant, ButtonVariantStyles } from "../Button.types";

export const secondaryButtonVariants: ButtonVariantStyles = {
  base: [
    "font-semibold",
    "uppercase",
    "rounded-[50px]",
    "active:text-content-primary-on-dark",
    "focus:text-green-accent-one",
    "focus:bg-grey-scale-off-black",
  ],
  light: [
    "text-grey-scale-off-black",
    "border-grey-scale-off-black",
    "hover:text-content-primary-on-dark",
    "hover:bg-grey-scale-off-black",
    "hover:border-grey-scale-off-black",
    "active:bg-green-dark",
    "active:border-green-dark",
  ],
  dark: [
    "text-content-primary-on-dark",
    "border-content-primary-on-dark",
    "hover:text-green-off-black",
    "hover:bg-content-primary-on-dark",
    "hover:border-green-off-black",
    "active:border-green-off-black",
    "active:bg-green-off-black",
  ],
  size: {
    big: "gap-4 px-10 py-4 text-base",
    medium: "gap-3 px-9 py-5 text-sm",
    small: "gap-2 px-5 py-3 text-sm",
  },
};

const { light, dark } = secondaryButtonVariants;

export const secondaryCompoundVariants: ButtonCompoundVariant[] = [
  {
    intent: "secondary",
    background: "light",
    className: light,
  },
  {
    intent: "secondary",
    background: "dark",
    className: dark,
  },
  {
    intent: "secondary",
    size: "big",
    className: "gap-4 px-10 py-4 text-base",
  },
  {
    intent: "secondary",
    size: "medium",
    className: "gap-3 px-9 py-5 text-sm",
  },
  {
    intent: "secondary",
    size: "small",
    className: "gap-2 px-5 py-3 text-sm",
  },
];
