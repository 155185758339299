import React, { SVGProps } from "react";

const Transformation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.46 63.46" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={52.35}
        x2={13.59}
        y1={21.76}
        y2={43.25}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#26a4e1" stopOpacity={0} />
        <stop offset={1} stopColor="#26a4e1" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={37.15}
        x2={24.4}
        y1={42.02}
        y2={52.34}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.28} stopOpacity={0} />
        <stop offset={0.91} />
      </linearGradient>
    </defs>
    <path
      d="M47.16 21.89c-.52 0-1.02.05-1.51.14-1.73-5.55-6.91-9.59-13.04-9.59-4.97 0-9.32 2.66-11.71 6.63-.96-.29-1.97-.44-3.02-.44-5.83 0-10.56 4.73-10.56 10.56s4.73 10.56 10.56 10.56c2.61 0 4.99-.95 6.83-2.52 2.23 1.58 4.95 2.52 7.89 2.52s5.88-1.01 8.16-2.7a8.879 8.879 0 0 0 6.39 2.7c4.93 0 8.93-4 8.93-8.93s-4-8.93-8.93-8.93Z"
      style={{
        fill: "#6cd7f3",
      }}
    />
    <path
      d="M30.19 27.27c-6.34-.55-16.24-.14-22.82 3.02.55 5.32 5.04 9.47 10.51 9.47 2.61 0 4.99-.95 6.83-2.52 2.23 1.58 4.95 2.52 7.89 2.52s5.88-1.01 8.16-2.7a8.879 8.879 0 0 0 6.39 2.7c4.37 0 8-3.14 8.77-7.28-8.78-.03-18.28-4.58-25.74-5.21Z"
      style={{
        fill: "url(#a)",
      }}
    />
    <rect
      width={18.4}
      height={6.13}
      x={22.46}
      y={45.16}
      rx={2.5}
      ry={2.5}
      style={{
        fill: "#14e37c",
      }}
    />
    <path
      d="M24.5 45.16a2 2 0 0 0-2 2v2.14a2 2 0 0 0 2 2h10.67l-1.23-1.56a12.03 12.03 0 0 0-9.44-4.58Z"
      style={{
        fill: "url(#b)",
        opacity: 0.3,
      }}
    />
    <path
      d="M19.6 48.31h-3.91c-1.1 0-1.99-.89-1.99-1.99V31.45"
      style={{
        stroke: "#009c3f",
        fill: "none",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: "2.3px",
      }}
    />
    <path
      d="M43.71 48.31h3.91c1.1 0 1.99-.89 1.99-1.99v-4.61"
      style={{
        fill: "none",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: "2.3px",
        stroke: "#6cd7f3",
      }}
    />
  </svg>
);

export default Transformation;
