import React, { SVGProps } from "react";

const TwitterNew = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="#E3F2FA"
    {...props}
  >
    <path d="M21 20h-6.174l-5.814-7.843L1.748 20H0l8.243-8.88L0 0h6.063l5.574 7.463L18.566 0h1.703l-7.864 8.492L21 20Zm-5.421-1.402h2.832L5.531 1.313H2.634l12.945 17.285Z" />
  </svg>
);

export default TwitterNew;
