import { NodeWithColumnsLayout } from "@layout/navigation/Nav.types";
import { cn } from "@utils/tailwind";
import Link from "next/link";
import { FC, Fragment, MouseEventHandler } from "react";
import { BlackLink } from "../black-link/BlackLink";
import { GreenLink } from "../green-link/GreenLink";
import styles from "./DropdownColumns.module.scss";

interface Props {
  node: NodeWithColumnsLayout;
  isMobile: boolean;
  onClickSubdropdown: MouseEventHandler<HTMLAnchorElement>;
}

export const DropdownColumns: FC<Props> = ({
  node,
  isMobile,
  onClickSubdropdown,
}) => {
  let prevBlackLink = false;
  return (
    <>
      <div className="flex basis-full xl:basis-9/12 2xl:basis-7/12">
        <div
          className="hidden w-[calc(125px+64px+16px)] lg:block"
          data-name="placeholder"
        />
        <ul
          role="menu"
          className="block w-full lg:grid lg:w-[calc(100%-125px-64px-16px)] lg:grid-cols-3 lg:gap-x-20"
        >
          {node.columns.map((column, idx) => (
            <li className="ml-6 block lg:ml-0" role="presentation" key={idx}>
              <Link
                role="menu-item"
                aria-expanded="false"
                aria-haspopup="true"
                href={column.link.href}
                className={cn(
                  styles.labelItem,
                  "block focus:lg:outline-disabled lg:inline-block",
                  "cursor-pointer  lg:cursor-default",
                  " text-nowrap border-grey-scale-gray  text-lg font-medium uppercase lg:border-b lg:text-xs lg:text-grey-scale-gray lg:opacity-50",
                  "py-3 lg:pb-4 lg:pt-0 "
                )}
                data-subdropdown={`subdropdown-${idx}`}
                data-nav-item="label-item"
                onClick={onClickSubdropdown}
                tabIndex={isMobile ? undefined : -1}
              >
                <span>
                  {column.link.name}
                  <span />
                </span>
              </Link>
              <ul
                role="menu"
                data-nav-item="menu-subdropdown"
                className="ml-5 hidden text-lg lg:ml-0 lg:block lg:text-sm"
                id={`subdropdown-${idx}`}
              >
                {column.children.map((innerColumn, idx) => (
                  <Fragment key={idx}>
                    <li
                      role="presentation"
                      className={cn(
                        `block`,
                        (innerColumn.children.length ||
                          prevBlackLink ||
                          idx === 0) &&
                          `lg:pt-6`
                      )}
                    >
                      <GreenLink link={innerColumn.link} />
                    </li>
                    {(prevBlackLink = false)}
                    {innerColumn.children.map((link, idx) => {
                      prevBlackLink = true;
                      return (
                        <li role="presentation" key={`c${idx}`}>
                          <BlackLink item={link} />
                        </li>
                      );
                    })}
                  </Fragment>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
