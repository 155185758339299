export const isExternal = (link: string): boolean => {
  if (link) {
    return !(
      link.includes("www.virtuslab.com") ||
      link.includes("/virtuslab.com") ||
      link.charAt(0) === "/"
    );
  }
  return false;
};
