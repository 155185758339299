import { tw } from "@utils/tailwind";
import { ButtonCompoundVariant, ButtonVariantStyles } from "../Button.types";

export const secondaryV2ButtonVariants: ButtonVariantStyles = {
  base: tw`
    rounded-full
    bg-transparent
    font-termina
    font-medium hover:border-green-accent-one hover:bg-green-accent-one
    focus:border-green-accent-two focus:bg-transparent
  `,
  light: [
    "border-content-primary-on-light text-content-primary-on-light focus:text-content-primary-on-light",
  ],
  dark: [
    "border-content-primary-on-dark text-content-primary-on-dark hover:text-content-primary-on-light focus:text-content-primary-on-dark",
  ],
};

const { light, dark } = secondaryV2ButtonVariants;

export const secondaryV2CompoundVariants: ButtonCompoundVariant[] = [
  {
    intent: "secondaryV2",
    background: "light",
    className: light,
  },
  {
    intent: "secondaryV2",
    background: "dark",
    className: dark,
  },
  {
    intent: "secondaryV2",
    size: "big",
    className: "xl:px-12 xl:py-4 px-9 py-3 xl:text-base text-xs",
  },
  {
    intent: "secondaryV2",
    size: "medium",
    className: "xl:px-12 xl:py-4 px-9 py-3 xl:text-base text-xs",
  },
  {
    intent: "secondaryV2",
    size: "small",
    className: "px-9 py-3 text-xs",
  },
];
