import Typography from "@atoms/typography/Typography";
import { cn } from "@utils/tailwind";

export const UnderInputText = ({
  successMsg,
  errorMsg,
}: {
  successMsg: string;
  errorMsg: string;
}) => {
  let text = "Placeholder";
  if (successMsg) text = successMsg;
  if (errorMsg) text = errorMsg;

  return (
    <Typography
      tag="p"
      variant="xs"
      className={cn(
        "invisible pb-4",
        successMsg && "visible text-green-accent-one",
        errorMsg && "visible text-orange-saturated"
      )}
    >
      {text}
    </Typography>
  );
};
