import React, { SVGProps } from "react";

const Thick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="#00C859"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.47693 5.74993L9.13842 0L10 0.875037L3.47693 7.5L0 3.96876L0.861579 3.09372L3.47693 5.74993Z"
    />
  </svg>
);

export default Thick;
