import { SVGProps } from "react";

const Quote = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="13"
    viewBox="0 0 18 13"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.963 7C5.879 7.774 5.655 8.392 5.265 8.94C4.742 9.671 3.865 10.354 2.415 11.08C2.32198 11.1209 2.23824 11.1803 2.16886 11.2545C2.09948 11.3287 2.04591 11.4163 2.0114 11.5119C1.9769 11.6075 1.96217 11.7091 1.96812 11.8105C1.97407 11.9119 2.00057 12.0111 2.04601 12.102C2.09146 12.1929 2.15489 12.2736 2.23248 12.3392C2.31006 12.4048 2.40017 12.454 2.49733 12.4837C2.59449 12.5135 2.69669 12.5232 2.79771 12.5122C2.89873 12.5013 2.99647 12.4699 3.085 12.42C4.635 11.646 5.758 10.829 6.485 9.81C7.226 8.774 7.5 7.607 7.5 6.25V1.75C7.5 1.28587 7.31563 0.840752 6.98744 0.512563C6.65925 0.184375 6.21413 0 5.75 0H1.75C1.28587 0 0.840752 0.184375 0.512563 0.512563C0.184375 0.840752 0 1.28587 0 1.75V5.25C0 6.216 0.784 7 1.75 7H5.963ZM15.963 7C15.879 7.774 15.655 8.392 15.265 8.94C14.742 9.671 13.865 10.354 12.415 11.08C12.322 11.1209 12.2382 11.1803 12.1689 11.2545C12.0995 11.3287 12.0459 11.4163 12.0114 11.5119C11.9769 11.6075 11.9622 11.7091 11.9681 11.8105C11.9741 11.9119 12.0006 12.0111 12.046 12.102C12.0915 12.1929 12.1549 12.2736 12.2325 12.3392C12.3101 12.4048 12.4002 12.454 12.4973 12.4837C12.5945 12.5135 12.6967 12.5232 12.7977 12.5122C12.8987 12.5013 12.9965 12.4699 13.085 12.42C14.635 11.646 15.758 10.829 16.485 9.81C17.226 8.774 17.5 7.607 17.5 6.25V1.75C17.5 1.28587 17.3156 0.840752 16.9874 0.512563C16.6592 0.184375 16.2141 0 15.75 0H11.75C11.2859 0 10.8408 0.184375 10.5126 0.512563C10.1844 0.840752 10 1.28587 10 1.75V5.25C10 6.216 10.784 7 11.75 7H15.963Z"
      fill="#181818"
    />
  </svg>
);

export default Quote;
