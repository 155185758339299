import React, { SVGProps } from "react";

const Optimisation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.46 63.46" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={9743.67}
        x2={9739.2}
        y1={9688.64}
        y2={9708.01}
        gradientTransform="matrix(0 -1 -1 0 9742.49 9775.62)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.28} stopOpacity={0} />
        <stop offset={0.91} />
      </linearGradient>
      <linearGradient
        id="b"
        x1={7.75}
        x2={31.58}
        y1={31.59}
        y2={31.59}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.17} stopColor="#fe9c5a" />
        <stop offset={0.42} stopColor="#fead6d" />
        <stop offset={0.71} stopColor="#fdc88c" />
        <stop offset={1} stopColor="#fdeab3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={-3270.68}
        x2={-3281.79}
        y1={-3274.78}
        y2={-3295.67}
        gradientTransform="translate(3290.94 3319.48)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ff9754" />
        <stop offset={0.75} stopColor="#ff9754" stopOpacity={0} />
      </linearGradient>
    </defs>
    <circle
      cx={44.47}
      cy={25.29}
      r={1.3}
      style={{
        fill: "#008df7",
      }}
    />
    <path
      d="M42.36 41.42c-2.64 2.96-6.48 4.82-10.75 4.82s-8.11-1.86-10.75-4.82"
      style={{
        stroke: "#ff9754",
        fill: "none",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: "2.3px",
      }}
    />
    <path
      d="M34.79 26.88v9.41c0 .89.48 1.72 1.25 2.17l8.15 4.71c.77.45 1.73.45 2.5 0l8.15-4.71c.77-.45 1.25-1.27 1.25-2.17v-9.41c0-.89-.48-1.72-1.25-2.17L46.69 20c-.77-.45-1.73-.45-2.5 0l-8.15 4.71c-.77.45-1.25 1.27-1.25 2.17Z"
      style={{
        fill: "#14e37c",
      }}
    />
    <path
      d="m55.29 38.2-9.85 5.69-10.65-6.15V26.36l20.5 11.84z"
      style={{
        fill: "url(#a)",
        opacity: 0.3,
      }}
    />
    <path
      d="M21.02 22.08a14.37 14.37 0 0 1 10.59-4.64c5.62 0 10.49 3.22 12.86 7.91"
      style={{
        fill: "none",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
        strokeWidth: "2.3px",
        stroke: "#14e37c",
      }}
    />
    <path
      d="M24.37 42.24h-9.41c-.89 0-1.72-.48-2.17-1.25l-4.71-8.15c-.45-.77-.45-1.73 0-2.5l4.71-8.15c.45-.77 1.27-1.25 2.17-1.25h9.41c.89 0 1.72.48 2.17 1.25l4.71 8.15c.45.77.45 1.73 0 2.5l-4.71 8.15c-.45.77-1.27 1.25-2.17 1.25Z"
      style={{
        fill: "url(#b)",
      }}
    />
    <path
      d="m13.05 21.74-5.69 9.85 6.16 10.65h11.37l-11.84-20.5z"
      style={{
        fill: "url(#c)",
      }}
    />
  </svg>
);

export default Optimisation;
